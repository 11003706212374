import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'app',
    initialState: {
        offering: null, // deprecated in mobil, TODO web
        offeringProductList: [], // deprecated in mobil, TODO web

        iapOn: false,
        iapProductList: [],

        cvNewFlowEnabled: true,
        cvPreviewEnabled: false,
        reviewModeEnabled: false,
        betaTesterModeEnabled: false,

        cvOptionsBSIndex: 0,
        cvStyleBSIndex: -1,
        cvTextMoreBSIndex: -1,
       

        spin: false,
        version: null

    },
    reducers: {
        setOffering(state, action) {
            state.offering = action.payload
        },
        setOfferingProductList(state, action) {
            state.offeringProductList = action.payload
        },
        setIapOn(state, action) {
            state.iapOn = action.payload
        },
        setIapProductList(state, action) {
            state.iapProductList = action.payload
        },
        setCvNewFlowEnabled(state, action) {
            state.cvNewFlowEnabled = action.payload
        },
        setCvPreviewEnabled(state, action) {
            state.cvPreviewEnabled = action.payload
        },
        setReviewModeEnabled(state, action) {
            state.reviewModeEnabled = action.payload
        },
        setBetaTesterModeEnabled(state, action) {
            state.betaTesterModeEnabled = action.payload
        },
        setCvStyleBSIndex(state, action) {
            state.cvStyleBSIndex = action.payload
        },
        setCvTextMoreBSIndex(state, action) {
            state.cvTextMoreBSIndex = action.payload
        },
        setCvOptionsBSIndex(state, action) {
            state.cvOptionsBSIndex = action.payload
        },
        setSpin(state, action) {
            state.spin = action.payload
        },
        setVersion(state, action) {
            state.version = action.payload
        }
    }
})

export default appSlice