import { useCallback, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { collection, query, onSnapshot, orderBy, updateDoc, doc, setDoc } from "firebase/firestore";
import { Button, Card, CardBody, Input, Textarea } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";

import { illustrations } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { useColorScheme } from "../../../controllers/hooks";
import { errorHandler } from "../../../controllers/app";
import { auth, db } from "../../../controllers/firebase"

import { AlertContext } from "../../../contexts/AlertContext";

import WebAddCVButton from "../../molecules/buttons/WebAddCVButton";
import WebSpinnerView from "../../molecules/views/WebSpinnerView";
import WebErrorView from "../../molecules/views/WebErrorView";
import WebCenterView from "../../molecules/views/WebCenterView";
import WebIcon from "../../atoms/WebIcon";
import WebListItemHeaderView from "../../molecules/views/WebListItemHeaderView";


const WebFeatureRequestList = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const { uid } = auth.currentUser || {}
    const { language } = user

    // ------------------------------------------------------------------------------------------------------------------------
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingItem, setLoadingItem] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)

    const [featureList, setFeatureList] = useState([])
    const [retry, setRetry] = useState(false);
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    // ------------------------------------------------------------------------------------------------------------------------
    const addFeature = useCallback(() => {

        setLoadingAdd(true)
        const ref = doc(collection(db, "00_USERS_FEATURE_REQUESTS_00"));
        setDoc(doc(db, "00_USERS_FEATURE_REQUESTS_00", ref.id), {
            id: ref.id,
            title, description, uid,
            votedCount: 1, votedBy: [uid],
            createdAt: new Date().getTime(), status: "DRAFT"
        })
            .then(() => onClose())
            .catch(e => errorHandler(alert, "err_add_feature_request", e))
            .finally(() => setLoadingAdd(false))

    }, [title, description, uid, alert, onClose])


    const onItemPress = useCallback((id, votedBy, votedCount) => {
        setLoadingItem(id)
        const updateData = { votedCount: votedCount + 1, votedBy: [...votedBy, uid] }
        updateDoc(doc(db, "00_USERS_FEATURE_REQUESTS_00", id), updateData)
            .catch(e => errorHandler(alert, "err_update_feature_request_item", e))
            .finally(() => setLoadingItem(false))
    }, [alert])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        setLoading(true);
        setError(false)
        const q = query(collection(db, "00_USERS_FEATURE_REQUESTS_00"), orderBy("votedCount", "desc"));
        const fbSub = onSnapshot(q, s => {
            setFeatureList(_.filter(s.docs.map(e => e.data()), i => i.status !== "DRAFT" || i.uid === uid))
            setLoading(false)
        }, e => errorHandler(alert, "err_fb_cv_snapshot", e, true, () => { setLoading(false); setError(true) }));
        return () => fbSub()
    }, [d, uid, retry])


    // ------------------------------------------------------------------------------------------------------------------------
    return error ? (
        <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
            <WebErrorView buttonProps={{ onClick: () => setRetry(!retry) }} />
        </div>
    ) : loading ? (
        <WebSpinnerView />
    ) : featureList.length === 0 ? (
        <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
            <WebCenterView
                imageProps={{ src: illustrations.idea }}
                title={t("requestFeature")}
                message={t("requestFeatureMsg")}
            >
                <Button
                    color="primary"
                    className="mt-6"
                    onPress={onOpen}
                >
                    {t("add")}
                </Button>
            </WebCenterView>
        </div>
    ) : (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-8">
                {featureList.map(({ id, title, titleLocalized, description, descriptionLocalized, votedCount, votedBy }) => (
                    <Card shadow={"none"}>
                        <CardBody className="justify-between">
                            <p className="font-bold">{titleLocalized?.[language] || title}</p>
                            <p className="text-xs font-light">{descriptionLocalized?.[language] || description}</p>

                            <div className="flex justify-end">
                                <Button
                                    color={votedBy.includes(uid) ? "primary" : "default"}
                                    variant="light"
                                    isLoading={loadingItem === id}
                                    isDisabled={votedBy.includes(uid)}
                                    endContent={<WebIcon name="arrow_drop_up" />}
                                    onPress={() => onItemPress(id, votedBy, votedCount)}
                                >
                                    {votedCount}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                ))}
                <Card isPressable shadow={"none"} onClick={() => onOpen()} className="h-full">
                    <CardBody className="items-center justify-center">
                        <span className="material-symbols-outlined filled text-6xl text-primary">add_circle</span>
                    </CardBody>
                </Card>
            </div>

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                onClose={() => { setTitle(""); setDescription("") }}
                hideCloseButton={true}
                size={"2xl"}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t("newRequest")}</ModalHeader>
                            <ModalBody className="gap-0">
                                <WebListItemHeaderView text={t("title")} />
                                <Input
                                    autoFocus
                                    placeholder={t("title")}
                                    onValueChange={setTitle}
                                />
                                <WebListItemHeaderView text={t("description")} />
                                <Textarea
                                    placeholder={t("writeHerePh")}
                                    onValueChange={setDescription}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" isDisabled={loadingAdd} onPress={onClose}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    color="primary"
                                    isLoading={loadingAdd}
                                    isDisabled={!title || !description}
                                    onPress={() => addFeature()}
                                >
                                    {t("add")}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default WebFeatureRequestList