module.exports = {
    colors: {
        primary: "#247BA0",
        blackOpacity: v => `rgba(0, 0, 0, 0.${v})`,

        // https://developer.apple.com/design/human-interface-guidelines/color#Specifications
        light: {
            red: "rgb(255, 59, 48)",
            orange: "rgb(255, 149, 0)",
            yellow: "rgb(255, 204, 0)",
            blue: "rgb(0, 122, 255)", // #006fe6 (for admob contrast rule)
            green: "rgb(52, 199, 89)",
            pink: "rgb(255, 45,85)",
            background: "rgb(242, 242, 242)",
            card: 'rgb(255, 255, 255)', // white
            text: 'rgb(28, 28, 30)',
            border: 'rgb(216, 216, 216)',
            gray: "rgb(142,142,147)",
            gray2: "rgb(174,174,178)",
            gray3: "rgb(199,199,204)",
            gray4: "rgb(209,209,214)",
            gray5: "rgb(229,229,234)",
            gray6: "rgb(242,242,247)"
        },

        dark: {
            red: "rgb(255, 69, 58)",
            orange: "rgb(255, 159, 0)",
            yellow: "rgb(255, 214, 10)",
            blue: "rgb(10, 132, 255)",
            green: "rgb(48, 209, 89)",
            pink: "rgb(255, 55, 95)",
            background: "rgb(1, 1, 1)", // black
            card: 'rgb(33, 33, 36)', // semiblack
            text: 'rgb(229, 229, 231)',
            border: 'rgb(39, 39, 41)',
            gray: "rgb(142,142,147)",
            gray2: "rgb(99,99,102)",
            gray3: "rgb(72,72,74)",
            gray4: "rgb(58,58,60)",
            gray5: "rgb(44,44,46)",
            gray6: "rgb(28,28,30)"
        }
    },

    margin: {

        A40: { margin: 40 },
        A30: { margin: 30 },
        A25: { margin: 25 },
        A20: { margin: 20 },
        A15: { margin: 15 },
        A10: { margin: 10 },
        A5: { margin: 5 },
        A0: { margin: 0, marginLeft: 0, marginTop: 0, marginRight: 0, marginBottom: 0 },
        NA5: { margin: -5 },
        NA10: { margin: -10 },
        NA15: { margin: -15 },
        NA20: { margin: -20 },
        NA25: { margin: -25 },
        NA30: { margin: -30 },

        T100: { marginTop: 100 },
        T50: { marginTop: 50 },
        T40: { marginTop: 40 },
        T30: { marginTop: 30 },
        T25: { marginTop: 25 },
        T20: { marginTop: 20 },
        T15: { marginTop: 15 },
        T10: { marginTop: 10 },
        T5: { marginTop: 5 },
        T0: { marginTop: 0 },
        NT5: { marginTop: -5 },
        NT10: { marginTop: -10 },
        NT15: { marginTop: -15 },
        NT20: { marginTop: -20 },
        NT25: { marginTop: -25 },
        NT30: { marginTop: -30 },

        R40: { marginRight: 40 },
        R35: { marginRight: 35 },
        R30: { marginRight: 30 },
        R25: { marginRight: 25 },
        R20: { marginRight: 20 },
        R15: { marginRight: 15 },
        R10: { marginRight: 10 },
        R5: { marginRight: 5 },
        R0: { marginRight: 0 },
        NR5: { marginRight: -5 },
        NR10: { marginRight: -10 },
        NR15: { marginRight: -15 },
        NR20: { marginRight: -20 },
        NR25: { marginRight: -25 },
        NR30: { marginRight: -30 },

        B50: { marginBottom: 50 },
        B40: { marginBottom: 40 },
        B30: { marginBottom: 30 },
        B25: { marginBottom: 25 },
        B20: { marginBottom: 20 },
        B15: { marginBottom: 15 },
        B10: { marginBottom: 10 },
        B5: { marginBottom: 5 },
        B0: { marginBottom: 0 },
        NB5: { marginBottom: -5 },
        NB10: { marginBottom: -10 },
        NB15: { marginBottom: -15 },
        NB20: { marginBottom: -20 },
        NB25: { marginBottom: -25 },
        NB30: { marginBottom: -30 },

        L40: { marginLeft: 40 },
        L35: { marginLeft: 35 },
        L30: { marginLeft: 30 },
        L25: { marginLeft: 25 },
        L20: { marginLeft: 20 },
        L15: { marginLeft: 15 },
        L10: { marginLeft: 10 },
        L5: { marginLeft: 5 },
        L0: { marginLeft: 0 },
        NL5: { marginLeft: -5 },
        NL10: { marginLeft: -10 },
        NL15: { marginLeft: -15 },
        NL20: { marginLeft: -20 },
        NL25: { marginLeft: -25 },
        NL30: { marginLeft: -30 },

    },

    padding: {

        A40: { padding: 40 },
        A30: { padding: 30 },
        A25: { padding: 25 },
        A20: { padding: 20 },
        A15: { padding: 15 },
        A10: { padding: 10 },
        A5: { padding: 5 },
        A0: { padding: 0 },

        LR10: { paddingLeft: 10, paddingRight: 10 },

        T40: { paddingTop: 40 },
        T30: { paddingTop: 30 },
        T25: { paddingTop: 25 },
        T20: { paddingTop: 20 },
        T15: { paddingTop: 20 },
        T10: { paddingTop: 10 },
        T5: { paddingTop: 5 },
        T0: { paddingTop: 0 },

        R40: { paddingRight: 40 },
        R30: { paddingRight: 30 },
        R25: { paddingRight: 25 },
        R20: { paddingRight: 20 },
        R15: { paddingRight: 15 },
        R10: { paddingRight: 10 },
        R5: { paddingRight: 5 },
        R0: { paddingRight: 0 },

        B50: { paddingBottom: 50 },
        B40: { paddingBottom: 40 },
        B30: { paddingBottom: 30 },
        B25: { paddingBottom: 25 },
        B20: { paddingBottom: 20 },
        B15: { paddingBottom: 15 },
        B10: { paddingBottom: 10 },
        B5: { paddingBottom: 5 },
        B0: { paddingBottom: 0 },

        L40: { paddingLeft: 40 },
        L30: { paddingLeft: 30 },
        L25: { paddingLeft: 25 },
        L20: { paddingLeft: 20 },
        L15: { paddingLeft: 15 },
        L10: { paddingLeft: 10 },
        L5: { paddingLeft: 5 },
        L0: { paddingLeft: 0 },

    },

    typography: {
        // basic styles
        bold: { fontWeight: "800" },

        // font style
        semibold: { fontWeight: "600" },
        mediumItalic: { fontWeight: "500", fontStyle: 'italic' },
        medium: { fontWeight: "500" },
        italic: { fontStyle: 'italic' },
        regular: { fontWeight: "400" },
        underline: { textDecorationLine: "underline", textDecorationStyle: "solid", textDecorationColor: "#000" },

        // layout
        left: { textAlign: 'left' },
        center: { textAlign: "center" },
        right: { textAlign: "right" },

        // sizes
        largeTitleEmphasized: { fontSize: 34, fontWeight: "700" },
        header: { fontSize: 26 },
        title: { fontSize: 22 },
        title3: { fontSize: 20 },
        title3Emphasized: { fontSize: 20, fontWeight: "600" },
        body: { fontSize: 17 },
        bodyEmphasized: { fontSize: 17, fontWeight: "600" },
        headline: { fontSize: 16 },
        subhead: { fontSize: 15 },
        subheadShort: { fontSize: 15 },
        subheadEmphasized: { fontSize: 15, fontWeight: "600" },
        callout: { fontSize: 16 },
        footnote: { fontSize: 13 },
        footnoteEmphasized: { fontSize: 13, fontWeight: "600" },
        caption: { fontSize: 12 },
        caption2: { fontSize: 11 },
        caption2Emphasized: { fontSize: 11, fontWeight: "600" }
    },

    layout: {
        centerScrollView: { maxWidth: 700, alignSelf: 'center', width: '100%' },

        flex0: { flex: 0 },
        flex05: { flex: 0.5 },
        flex075: { flex: 0.75 },
        flex: { flex: 1 },
        flex2: { flex: 2 },

        row: { flexDirection: 'row' },
        column: { flexDirection: 'column' },

        center: { alignItems: 'center', justifyContent: 'center' },
        centerH: { justifyContent: 'center' },
        centerV: { alignItems: 'center' },

        borderRadiusAll: {
            android: { borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
            ios: { borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }
        },
        borderRadiusTop: {
            android: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
            ios: { borderTopLeftRadius: 20, borderTopRightRadius: 20 }
        },
        borderRadiusBottom: {
            android: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
            ios: { borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }
        },

        borderRadiusBottom0: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
        borderRadiusTop0: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
        borderRadius0: { borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
        borderDotted: { borderWidth: 3, borderStyle: 'dashed' },

        shadow: (shadowOpacity = 0.25, shadowRadius = 3.84, elevation = 5, shadowColor = "#000", shadowOffset = { width: 0, height: 0 }) => ({
            shadowColor, shadowOffset, shadowOpacity, shadowRadius, elevation
        }),

        chatArrow: (pos = "left", over = false) => {
            const additionals = {
                left: { borderBottomRightRadius: over ? 18 : 25, left: over ? -20 : -10 },
                right: { borderBottomLeftRadius: over ? 18 : 25, right: over ? -20 : -11 }
            }
            return {
                position: "absolute",
                width: 20,
                height: over ? 35 : 25,
                bottom: over ? -6 : 0,
                ...additionals[pos]
            }
        },

        photoFormat: (badge = false) => {

            const radiusL = badge ? 30 : 75;
            const radiusM = badge ? 15 : 35;
            const radiusS = badge ? 5 : 15;

            return {
                square: {},
                default: { borderRadius: radiusL },
                rounded: { borderRadius: radiusS },
                drop_br: { borderTopLeftRadius: radiusL, borderTopRightRadius: radiusL, borderBottomLeftRadius: radiusL },
                drop_br: { borderTopLeftRadius: radiusL, borderTopRightRadius: radiusL, borderBottomLeftRadius: radiusL },
                drop_bl: { borderTopLeftRadius: radiusL, borderTopRightRadius: radiusL, borderBottomRightRadius: radiusL },
                drop_tr: { borderTopLeftRadius: radiusL, borderBottomLeftRadius: radiusL, borderBottomRightRadius: radiusL },
                drop_tl: { borderTopRightRadius: radiusL, borderBottomLeftRadius: radiusL, borderBottomRightRadius: radiusL },
                drop_tl_br: { borderTopRightRadius: radiusM, borderBottomLeftRadius: radiusM, },
                drop_tr_bl: { borderTopLeftRadius: radiusM, borderBottomRightRadius: radiusM },
                drop_tr_tl: { borderTopRightRadius: radiusM, borderTopLeftRadius: radiusM },
                drop_br_tr: { borderBottomRightRadius: radiusM, borderTopRightRadius: radiusM },
                drop_br_bl: { borderBottomRightRadius: radiusM, borderBottomLeftRadius: radiusM },
                drop_tl_bl: { borderTopLeftRadius: radiusM, borderBottomLeftRadius: radiusM }
            }

        }
    }
}