import { Listbox, ListboxItem, Card, CardBody } from "@nextui-org/react";
import { useMemo } from "react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar } from "@nextui-org/react";
import { useLocation, useNavigate, useParams, Outlet } from "react-router";
import { useSelector } from 'react-redux'

import { getCvImage } from "cvpop-utility-sdk";
import { t } from "cvpop-localization-sdk"
import { icons } from 'cvpop-assets-sdk';

import _ from "lodash"

import { useColorScheme } from "../../../../controllers/hooks";

import WebIcon from "../../../atoms/WebIcon"
import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";


const WebCVEdit = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { LearnerInfo } = cv.SkillsPassport
    const { ExpertiseArea = [], Ability = [], Hobby = [], Portfolio = [], Achievement = [], Education = [], WorkExperience = [], DriverLicenceSpecial = [], Attachment = [] } = LearnerInfo
    const { Identification, Headline, Skills, Video } = LearnerInfo
    const { Signature, CoverLetter, Summary, PersonName, ContactInfo, ProfessionalTitle } = Identification
    const { Telephone = [], Website = [], SocialAccount = [], InstantMessaging = [] } = ContactInfo
    const { Contact } = ContactInfo.Email
    const { FirstName, Surname } = PersonName
    const { Linguistic, Driving, Other, Communication, Organisational, JobRelated, Computer } = Skills
    const { MotherTongue = [], ForeignLanguage = [] } = Linguistic

    // ------------------------------------------------------------------------------------------------------
    const badgeProps = useMemo(() => ({ className: "text-xs mr-1 text-center rounded-full overflow-hidden bg-primary text-primary-foreground", style: { width: 18, height: 18, lineHeight: "18px" } }), [])

    // ------------------------------------------------------------------------------------------------------------------------
    if (![`/app/${platform}/cv/edit/`, `/app/${platform}/cv/edit`].includes(pathname)) return <Outlet />
    return (
        <div>
            <WebHeader title={t("editCv")} />

            <div className="mx-5 mb-5">
                <WebListItemHeaderView text={t('cvLabelPersonalInfo')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            onAction={k => k !== "contacts" && n(`/app/${platform}/cv/edit/${k}`)}
                            variant={"flat"}

                        >
                            <ListboxItem
                                key="personal-data"
                                title={t('personalData')}
                                startContent={<span className="material-symbols-outlined">id_card</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(FirstName && Surname && Contact && ProfessionalTitle) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="photo"
                                title={t('photo')}
                                startContent={<span className="material-symbols-outlined">account_box</span>}
                                endContent={
                                    <div className="flex items-center">
                                        <Avatar src={getCvImage(cv, platform) || icons.avatarcv} className="w-5 h-5 mr-1" />
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                }
                            />


                            <ListboxItem
                                key="contacts"
                                startContent={<span className="material-symbols-outlined">call</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                <Dropdown backdrop={"blur"} className={`${cs} text-foreground`}>
                                    <DropdownTrigger>
                                        {t('contacts')}
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        aria-label="Dynamic Actions"
                                        variant={"flat"}
                                        onAction={k => n(`/app/${platform}/cv/edit/${k}`)}
                                    >
                                        <DropdownItem
                                            key={"contact-info-phones"}
                                            title={t('phones')}
                                            startContent={<span className="material-symbols-outlined">call</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(Telephone.length > 0) && (<p {...badgeProps}>{Telephone.length}</p>)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"contact-info-websites"}
                                            title={t('websites')}
                                            startContent={<span className="material-symbols-outlined">language</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(Website.length > 0) && (<p {...badgeProps}>{Website.length}</p>)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"contact-info-social-accounts"}
                                            title={t('socialAccount')}
                                            startContent={<span className="material-symbols-outlined">groups</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(SocialAccount.length > 0) && (<p {...badgeProps}>{SocialAccount.length}</p>)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"contact-info-instant-messaging"}
                                            title={t('instMessages')}
                                            startContent={<span className="material-symbols-outlined">chat</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(InstantMessaging.length > 0) && (<p {...badgeProps}>{InstantMessaging.length}</p>)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                    </DropdownMenu>
                                </Dropdown>
                            </ListboxItem>

                        </Listbox>
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('cvBase')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            onAction={k => k !== "skills" && n(`/app/${platform}/cv/edit/${k}`)}
                            variant={"flat"}

                        >
                            <ListboxItem
                                key="summary"
                                title={t('cvLabelSummary')}
                                startContent={<span className="material-symbols-outlined">grading</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Summary?.Description) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="work-experience"
                                title={t('cvLabelExperience')}
                                startContent={<span className="material-symbols-outlined">business_center</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(WorkExperience.length > 0) && (<p {...badgeProps}>{WorkExperience.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="education"
                                title={t('cvLabelEducation')}
                                startContent={<span className="material-symbols-outlined">school</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Education.length > 0) && (<p {...badgeProps}>{Education.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />

                            <ListboxItem
                                key="skills"
                                startContent={<span className="material-symbols-outlined">psychology</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                <Dropdown backdrop={"blur"} className={`${cs} text-foreground`}>
                                    <DropdownTrigger>
                                        {t('cvLabelSkills')}
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        aria-label="Dynamic Actions"
                                        variant={"flat"}
                                        onAction={k => n(`/app/${platform}/cv/edit/${k}`)}
                                    >
                                        <DropdownItem
                                            key={"communication-skills"}
                                            title={t('cvLabelSkillsCommunication')}
                                            startContent={<span className="material-symbols-outlined">campaign</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(Communication?.Description) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"organisational-skills"}
                                            title={t('cvLabelSkillsOrganisational')}
                                            startContent={<span className="material-symbols-outlined">package_2</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(Organisational?.Description) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"job-related-skills"}
                                            title={t('cvLabelSkillsJobRelated')}
                                            startContent={<span className="material-symbols-outlined">lightbulb</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(JobRelated?.Description) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"computer-skills"}
                                            title={t('cvLabelSkillsComputer')}
                                            startContent={<span className="material-symbols-outlined">desktop_mac</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(Computer?.Description) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                        <DropdownItem
                                            key={"other-skills"}
                                            title={t('cvLabelSkillsOther')}
                                            startContent={<span className="material-symbols-outlined">trophy</span>}
                                            endContent={(
                                                <div className="flex items-center">
                                                    {(Other?.Description) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                                    <WebIcon name={"chevron_right"} />
                                                </div>
                                            )}
                                        />
                                    </DropdownMenu>
                                </Dropdown>
                            </ListboxItem>

                        </Listbox>
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('driverLicences')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/edit/${k}`)}
                            variant={"flat"}

                        >
                            <ListboxItem
                                key="driver-licence"
                                title={t('cvLabelDriverLicence')}
                                startContent={<span className="material-symbols-outlined">directions_car</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {((Driving?.Description || []).length > 0) && (<p {...badgeProps}>{(Driving?.Description || []).length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="special-driver-licence"
                                title={t('cvLabelSpecialDriverLicence')}
                                startContent={<span className="material-symbols-outlined">directions_bus</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(DriverLicenceSpecial.length > 0) && (<p {...badgeProps}>{DriverLicenceSpecial.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                        </Listbox>
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('languages')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/edit/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="mother-tongue"
                                title={t('cvLabelMotherTongue')}
                                startContent={<span className="material-symbols-outlined">home</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(MotherTongue.length > 0) && (<p {...badgeProps}>{MotherTongue.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="foreign-languages"
                                title={t('cvLabelForeignLanguage')}
                                startContent={<span className="material-symbols-outlined">translate</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(ForeignLanguage.length > 0) && (<p {...badgeProps}>{ForeignLanguage.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                        </Listbox>
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('cvAdvanced')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/edit/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="headline"
                                title={t('cvLabelHeadline')}
                                startContent={<span className="material-symbols-outlined">manage_search</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Headline?.Type?.Label && Headline?.Description?.Label) && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="keywords"
                                title={t('cvLabelKeyword')}
                                startContent={<span className="material-symbols-outlined">label</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(ExpertiseArea.length > 0) && (<p {...badgeProps}>{ExpertiseArea.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="abilities"
                                title={t('cvLabelAbility')}
                                startContent={<span className="material-symbols-outlined">align_horizontal_left</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Ability.length > 0) && (<p {...badgeProps}>{Ability.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="hobbies"
                                title={t('cvLabelHobby')}
                                startContent={<span className="material-symbols-outlined">favorite</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Hobby.length > 0) && (<p {...badgeProps}>{Hobby.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="portfolio"
                                title={t('cvLabelPortfolio')}
                                startContent={<span className="material-symbols-outlined">photo_library</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Portfolio.length > 0) && (<p {...badgeProps}>{Portfolio.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="achievements"
                                title={t('cvLabelAchievement')}
                                startContent={<span className="material-symbols-outlined">post_add</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {((Achievement || []).filter(i => i.Title.Code !== "signature_equivalent").length > 0) && (<p {...badgeProps}>{(Achievement || []).filter(i => i.Title.Code !== "signature_equivalent").length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="attachments"
                                title={t('cvLabelAttachment')}
                                startContent={<span className="material-symbols-outlined">attachment</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {(Attachment.length > 0) && (<p {...badgeProps}>{Attachment.length}</p>)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="signature"
                                title={t('signature')}
                                startContent={<span className="material-symbols-outlined">signature</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {Signature?.Data && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                            <ListboxItem
                                key="video-presentation"
                                title={t('cvLabelVideo')}
                                startContent={<span className="material-symbols-outlined">video_camera_front</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {Video?.url && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                        </Listbox>
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('other')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/edit/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="cover-letter"
                                title={t('coverLetter')}
                                startContent={<span className="material-symbols-outlined">article</span>}
                                endContent={(
                                    <div className="flex items-center">
                                        {CoverLetter?.Description && (<WebIcon name={"check_circle"} className={"filled text-primary"} />)}
                                        <WebIcon name={"chevron_right"} />
                                    </div>
                                )}
                            />
                        </Listbox>
                    </CardBody>
                </Card>

            </div>
        </div>
    )

}

export default WebCVEdit