import { getNewUserData } from "cvpop-utility-sdk";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";

import _ from "lodash"

import { db } from "./firebase";
import { getCountryCodeFromCity, setAppLocalization } from "./app";

export const postLogin = async ({ user, language, platform }) => {

    if (_.isEmpty(user)) return await Promise.reject({ code: 'postlogin/user-null' })

    const uid = user.uid;
    const countryCode = getCountryCodeFromCity()
    const newUserData = getNewUserData(user, language, countryCode, platform, window.navigator.userAgent)
    
    const udoc = await getDoc(doc(db, "00_USERS_00", uid))
    if (!udoc.exists()) await setDoc(doc(collection(db, "00_USERS_00"), uid), newUserData);

    const data = udoc.exists() ? udoc.data() : newUserData
    setAppLocalization(data.language)

    return udoc.exists() ? udoc.data() : newUserData
}