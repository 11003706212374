import { useState, useMemo, useCallback, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";
import { Spinner } from "@nextui-org/react";
import { httpsCallable } from "firebase/functions";
import { produce } from "immer";

import { t } from "cvpop-localization-sdk"
import { setCv } from "cvpop-redux-sdk";

import _ from "lodash"

import { AlertContext } from "../../../contexts/AlertContext";

import { errorHandler, openUrl } from "../../../controllers/app";
import { functions } from "../../../controllers/firebase";


const WebPublicLinkButton = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const getCvPublicIdApi = useMemo(() => httpsCallable(functions, 'getCVPublicLinkId'), []);

    // ------------------------------------------------------------------------------------------------------------------------
    const runAction = useCallback(() => {

        if (cv.Metadata.publicLinkId) return openUrl(`https://cvpop.com/cv/${cv.Metadata.publicLinkId}`, platform)
        setLoading(true)
        getCvPublicIdApi({ cvId: cv.Metadata.id })
            .then(({ data }) => {
                d(setCv({ cv: produce(cv, d => { d.Metadata.publicLinkId = data.publicLinkId }) }))
                openUrl(`https://cvpop.com/cv/${data.publicLinkId}`, platform)
            })
            .catch(e => errorHandler(alert, "err_get_cv_public_id_api", e))
            .finally(() => setLoading(false))

    }, [cv])


    // ------------------------------------------------------------------------------------------------------------------------ 
    return loading ? (
        <div className="flex flex-col items-center justify-center min-h-10">
            <Spinner size={"sm"} />
        </div>
    ) : (
        <div className="flex flex-col items-center" onClick={() => runAction()}>
            <span className="material-symbols-outlined">public</span>
            <span className="text-xs">{t(cv.Metadata.publicLinkId ? "onlineVersion" : "publishOnline")}</span>
        </div>
    )
}

export default WebPublicLinkButton