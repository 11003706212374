import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        userPro: false,
        userAms: false,
        userFreeAds: false,
        userCanBePro: false,
        userLogged: false,
        userStandardTrialEligible: true,
        userOfferTrialEligible: true,
        adUserChoices: false
    },
    reducers: {
        setUser(state, action) {
            state.user = action.payload.user
        },
        setUserAms(state, action) {
            state.userAms = action.payload
        },
        setUserFreeAds(state, action) {
            state.userFreeAds = action.payload
        },
        setUserPro(state, action) {
            state.userPro = action.payload
        },
        setUserCanBePro(state, action) {
            state.userCanBePro = action.payload
        },
        setUserLogged(state, action) {
            state.userLogged = action.payload
        },
        setUserStandardTrialEligible(state, action) {
            state.userStandardTrialEligible = action.payload
        },
        setUserOfferTrialEligible(state, action) {
            state.userOfferTrialEligible = action.payload
        },
        setAdUserChoices(state, action) {
            state.adUserChoices = action.payload
        },
    }
})

export default userSlice