import { useCallback, useState, useMemo, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Listbox, ListboxItem, Spinner } from "@nextui-org/react";
import { produce } from "immer"
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';

import { countries } from "cvpop-collections-sdk"
import { illustrations } from 'cvpop-assets-sdk';
import { getEnglishLabelFromLanguageCode, formatAddress } from 'cvpop-utility-sdk';
import { setCv } from 'cvpop-redux-sdk'
import { t } from "cvpop-localization-sdk";

import _ from "lodash"
import Markdown from 'react-markdown'

import { errorHandler, getCountryCodeFromCity, goToPaywall, openUrl } from '../../../../controllers/app';
import { db, functions } from '../../../../controllers/firebase';

import { AlertContext } from '../../../../contexts/AlertContext';

import WebIcon from '../../../atoms/WebIcon';
import WebCenterView from '../../../molecules/views/WebCenterView';
import WebHeader from '../../../molecules/views/WebHeader';
import WebErrorView from '../../../molecules/views/WebErrorView';
import WebListItemHeaderView from '../../../molecules/views/WebListItemHeaderView';


const WebCVSalary = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user, userPro } = useSelector(state => state.userReducer)
    const { cv } = useSelector(state => state.cvReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    const { alert, prompt } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate();
    const d = useDispatch();

    // ------------------------------------------------------------------------------------------------------
    const { professionalTitle, location, language } = user

    const { id } = cv?.Metadata || {};
    const { ProfessionalTitle } = cv?.SkillsPassport.LearnerInfo.Identification
    const { Address } = cv?.SkillsPassport.LearnerInfo.Identification.ContactInfo

    // ------------------------------------------------------------------------------------------------------
    const [salary, setSalary] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingAction, setLoadingAction] = useState(false)
    const [error, setError] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, "getCVSalary"), [functions])

    const countryCode = useMemo(() => getCountryCodeFromCity(), []);
    const defLocation = useMemo(() => (countries[language] || countries["en"]).find(e => e.code === countryCode)?.label || countryCode, [language, countryCode])

    // ------------------------------------------------------------------------------------------------------
    const getSalary = useCallback(() => {

        setLoading(true)
        setError(false)
        getDoc(doc(db, "00_RESUMES_QA_00", id))
            .then(doc => doc.exists() && setSalary(doc.data()))
            .catch(e => errorHandler(alert, "err_get_cv_salary", e, true, () => setError(true)))
            .finally(() => setLoading(false))

    }, [])

    const createSalary = useCallback(async () => {

        const salaryLocation = (formatAddress(Address?.Contact, language) || location) || defLocation

        let jobTitle = ProfessionalTitle
        if (!ProfessionalTitle)
            try { jobTitle = (await prompt(t('jobTitleNotPresentTitle'), t('jobTitleNotPresentMsg'), null, null, null, professionalTitle) || "").trim() } catch (e) { return }
        if (!jobTitle) return

        if (ProfessionalTitle !== jobTitle)
            d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle = jobTitle }) }))

        setLoadingAction(true)
        api({ cvId: id, location: salaryLocation, jobTitle, hLng: getEnglishLabelFromLanguageCode(language) })
            .then(({ data }) => setSalary(data))
            .catch(e => errorHandler(alert, "err_request_cv_salary", e))
            .finally(() => setLoadingAction(false))

    }, [id, language, location, defLocation, cv])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { getSalary() }, [])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("avgSalary")} />
            <div className="p-5">

                {
                    loading ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 150px)` }}>
                            <Spinner size="lg" />
                        </div>
                    ) : error ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 150px)` }}>
                            <WebErrorView buttonProps={{ onPress: getSalary }} />
                        </div>
                    ) : !salary ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.salary }}
                                title={t("avgSalaryTitle")}
                                message={t("avgSalaryMsg")}
                            >
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    isLoading={loadingAction}
                                    onPress={() => !userPro ? goToPaywall(offering, platform, n) : createSalary()}
                                    endContent={!userPro && (
                                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                            <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                                        </div>
                                    )}
                                >
                                    {t(!userPro ? "unlock" : "continue")}
                                </Button>
                            </WebCenterView>
                        </div>
                    ) : (
                        <div>
                            <h1 className='text-3xl mb-2 font-bold'>
                                {salary.jobTitle}
                            </h1>
                            <h1 className='text-xl mb-3'>
                                {salary.location}
                            </h1>
                            <Card shadow={"none"}>
                                <CardBody>
                                    <Markdown>{salary.message}</Markdown>
                                </CardBody>
                            </Card>
                            <WebListItemHeaderView text={`${t("dataAggregatedBy")} ${t("theseSources")}`} />
                            <Card shadow={"none"}>
                                <CardBody className="p-2">
                                    <Listbox aria-label="Actions" variant={"flat"}>
                                        {
                                            salary.citations.map((c, i) => (
                                                <ListboxItem
                                                    classNames={{ base: "w-auto opacity-100", title: "whitespace-normal" }}
                                                    key={`question${i}`}
                                                    title={c}
                                                    startContent={<WebIcon name={`looks_${(i + 1) === 1 ? "one" : (i + 1) === 2 ? "two" : i + 1}`} />}
                                                    endContent={<WebIcon name={"open_in_new"} />}
                                                    showDivider={salary.citations[i + 1]}
                                                    onPress={() => openUrl(c, platform)}
                                                />
                                            ))
                                        }

                                    </Listbox>
                                </CardBody>
                            </Card>
                            {
                                (ProfessionalTitle == salary.jobTitle) ? (
                                    <p className="mt-4 text-xs text-default-600">{t("updateCvToRetrySalaryTip")}</p>
                                ) : (
                                    <Button
                                        fullWidth
                                        color="primary"
                                        className="mt-4"
                                        endContent={!userPro && (
                                            <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                                <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                                            </div>
                                        )}
                                        onPress={() => createSalary()}
                                    >
                                        {t("tryAgain")}
                                    </Button>
                                )
                            }
                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default WebCVSalary