const { t } = require("cvpop-localization-sdk")

module.exports = {
    privacyLaw: cv => {

        const { privacyLaw, lng } = cv.Metadata

        if (!privacyLaw) return ""

        const locale = lng === 'no' ? 'nb' : lng
        const content = t(`privacyLaw${privacyLaw}Content`, { locale })

        return `
            <div class="privacy-law-container">
                <i class="privacy-law">
                    ${content}
                </i>
            </div>
        `

    }
}

