import React, { useCallback, useContext, useMemo, useState } from "react";
import { Input, Button, Card, CardBody, ButtonGroup } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { createFbId } from "../../../../controllers/app";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebKeyword = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const [newTitle, setNewTitle] = useState("")

    // ------------------------------------------------------------------------------------------------------
    const list = useMemo(() => cv.SkillsPassport.LearnerInfo.ExpertiseArea || [], [cv]);

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {
        const Id = createFbId()
        editCv(produce(cv, d => { d.SkillsPassport.LearnerInfo.ExpertiseArea.push({ Id, Title: newTitle }) }))
        setNewTitle("")
    }, [cv, newTitle])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.ExpertiseArea.filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.ExpertiseArea = newList
            })
        )
    }, [cv])

    const moveItem = useCallback((a, b) => {
        const copy = (cv.SkillsPassport.LearnerInfo.ExpertiseArea).slice();
        [copy[a], copy[b]] = [copy[b], copy[a]];
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.ExpertiseArea = copy }) }))
    }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelKeyword")} />
            <div className="p-5">
                <Card shadow={"none"}>
                    <CardBody>
                        {
                            list.map(({ Id, Title }, i) => (
                                <div key={`dlContainer${Id}`} className={`flex items-center ${i > 0 && "mt-3"}`}>
                                    <Input
                                        startContent={list.length > 1 && (
                                            <ButtonGroup variant={"light"}>
                                                <Button isDisabled={i === 0} isIconOnly size={"sm"} onClick={() => moveItem(i, i - 1)}>
                                                    <WebIcon name={"arrow_upward"} className={"text-base"} />
                                                </Button>
                                                <Button isDisabled={i == list.length - 1} isIconOnly size={"sm"} onClick={() => moveItem(i, i + 1)}>
                                                    <WebIcon name={"arrow_downward"} className={"text-base"} />
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                        key={`dl${Id}`}
                                        placeholder={t("keyword")}
                                        value={Title}
                                        onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.ExpertiseArea[${i}].Title`, target.value)))}
                                    />
                                    <Button
                                        isIconOnly
                                        color="danger"
                                        aria-label="Like"
                                        className="ml-3"
                                        onPress={() => confirmDelete().then(() => deleteItem(Id)).catch(() => { })}
                                    >
                                        <span className="material-symbols-outlined">delete</span>
                                    </Button>
                                </div>
                            ))
                        }

                        <div className={`flex ${list.length > 0 ? "mt-3" : ""} items-center`}>
                            <Input
                                key={"newDl"}
                                placeholder={t("keyword")}
                                value={newTitle}
                                onChange={({ target }) => setNewTitle(target.value)}
                                onKeyDown={({ key }) => (key === "Enter" && newTitle) && addItem()}
                            />
                            <Button isIconOnly color="primary" aria-label="Like" className="ml-3" isDisabled={!newTitle} onPress={addItem}>
                                <span className="material-symbols-outlined">add</span>
                            </Button>
                        </div>
                    </CardBody>
                </Card>

                <WebAIAssistantButton sectionId={"EXPERTISE_AREA"} />
            </div>

        </div>
    )
}

export default WebKeyword