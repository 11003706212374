import React, { useCallback, useContext, useEffect, useState, useMemo, useRef } from "react";
import { Card, Listbox, ListboxItem, Button, CardBody, Spinner } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { illustrations } from "cvpop-assets-sdk";
import { getEnglishLabelFromLanguageCode } from "cvpop-utility-sdk";
import { setCv, setSpin } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { db, functions } from "../../../../controllers/firebase";
import { errorHandler, goToPaywall } from "../../../../controllers/app";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebIcon from "../../../atoms/WebIcon"
import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";
import WebErrorView from "../../../molecules/views/WebErrorView";


const WebCVQA = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const ref = useRef()

    // ------------------------------------------------------------------------------------------------------
    const { id, updatedContentAt = 0 } = cv?.Metadata || {};

    // ------------------------------------------------------------------------------------------------------
    const [qa, setQA] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getCVAIQA', { timeout: 120000 }), []);

    // ------------------------------------------------------------------------------------------------------
    const getQA = useCallback(() => {

        setLoading(true)
        setError(false)
        getDoc(doc(db, "00_RESUMES_QA_00", id))
            .then(doc => doc.exists() && setQA(doc.data()))
            .catch(e => errorHandler(alert, "err_get_cv_qa", e, true, () => setError(true)))
            .finally(() => setLoading(false))
    }, [])

    const createQA = useCallback(async () => {

        d(setSpin(true))
        api({ cvId: id, hLng: getEnglishLabelFromLanguageCode(user.language) })
            .then(({ data }) => {
                setQA(data)
                ref?.current?.scrollTo(0, 0);
            })
            .catch(e => errorHandler(alert, "err_request_qa", e))
            .finally(() => d(setSpin(false)))

    }, [user]);

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { getQA() }, [])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("questionsAndAnswers")} />
            <div className="p-5">

                {
                    loading ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 150px)` }}>
                            <Spinner size="lg" />
                        </div>
                    ) : error ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 150px)` }}>
                            <WebErrorView buttonProps={{ onPress: getQA }} />
                        </div>
                    ) : !qa ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.question }}
                                title={t("questionsAndAnswers")}
                                message={t("questionsAndAnswersMsg")}
                            >
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    onPress={() => !userPro ? goToPaywall(offering, platform, n) : createQA()}
                                    endContent={!userPro && (
                                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                            <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                                        </div>
                                    )}
                                >
                                    {t(!userPro ? "unlock" : "showQuestions")}
                                </Button>
                            </WebCenterView>
                        </div>
                    ) : (
                        <div ref={ref}>

                            {
                                qa.questions.map(({ question, answer, comment }, i) => (
                                    <>
                                        <WebListItemHeaderView text={`${t('question')} #${i + 1}`} style={{ marginTop: i === 0 ? 0 : null }} />
                                        <Card shadow={"none"}>
                                            <CardBody className="p-2">
                                                <Listbox aria-label="Actions" disabledKeys={[`question${i}`, `answere${i}`, `comment${i}`]}>
                                                    <ListboxItem
                                                        classNames={{ base: "w-auto opacity-100", title: "whitespace-normal font-bold" }}
                                                        key={`question${i}`}
                                                        title={question}
                                                        startContent={<WebIcon name={"help_center"} />}
                                                        showDivider
                                                    />
                                                    <ListboxItem
                                                        classNames={{ base: "w-auto opacity-100", title: "whitespace-normal" }}
                                                        key={`answere${i}`}
                                                        title={answer}
                                                        startContent={<WebIcon name={"reply"} />}
                                                        showDivider
                                                    />
                                                    <ListboxItem
                                                        classNames={{ base: "w-auto opacity-100", title: "whitespace-normal italic text-xs text-default-500" }}
                                                        key={`comment${i}`}
                                                        title={comment}
                                                        startContent={<WebIcon name={"info"} />}
                                                    />
                                                </Listbox>
                                            </CardBody>
                                        </Card>
                                    </>

                                ))
                            }

                            {
                                (updatedContentAt < qa.createdAt) ? (
                                    <p className="mt-4 text-xs text-default-600">{t("updateCvToRetryQATip")}</p>
                                ) : (
                                    <Button
                                        fullWidth
                                        color="primary"
                                        className="mt-4"
                                        endContent={!userPro && (
                                            <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                                <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                                            </div>
                                        )}
                                        onPress={() => createQA()}
                                    >
                                        {t("tryAgain")}
                                    </Button>
                                )
                            }

                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default WebCVQA