const { sectionTitle } = require("./section-title")

module.exports = {
    hobbies: cv => {

        const { Hobby = [] } = cv.SkillsPassport.LearnerInfo
        if (Hobby.length === 0) return ""

        return `
            <div class="hobbies-container container">
                ${sectionTitle(cv, "cvLabelHobby", "Hobby", "fas fa-heart")}
                    <div class="hobbies-content content">
                        ${Hobby.map(({ Title, Icon }) => `
                            <div class="hobby-item ptXS">
                                <div class="hobby-icon-container">
                                    <i class="hobby-icon fa-solid fa-${Icon} fa-lg"></i>
                                </div>
                                <div class="hobby-title">
                                    ${Title}&nbsp;
                                </div>
                            </div>
                        `).join("")}
                    </div>
            </div>
            `
    }
}