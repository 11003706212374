import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { getBoolean } from "firebase/remote-config";

import { t } from "cvpop-localization-sdk"

import { remoteConfig } from "../../../controllers/firebase";
import { useColorScheme } from "../../../controllers/hooks";

import WebIcon from "../../atoms/WebIcon";



const WebAICVButton = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const cvQAOn = useMemo(() => (getBoolean(remoteConfig, "cvQA")), [])
    const cvAnalysisOn = useMemo(() => (getBoolean(remoteConfig, "cvAnalysis")), [])
    const cvSalaryOn = useMemo(() => (getBoolean(remoteConfig, "cvSalary")), [])

    // ------------------------------------------------------------------------------------------------------
    return (
        <Dropdown
            backdrop={"blur"}
            placement="right"
            className={`${cs} text-foreground`}
        >
            <DropdownTrigger>
                <div className="flex flex-col items-center">
                    <span className="material-symbols-outlined">smart_toy</span>
                    <span className="text-xs">{t("aiAssistant")}</span>
                </div>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" color={"primary"} onAction={k => n(`/app/${platform}/cv/${k}`)}>
                {
                    cvAnalysisOn && (
                        <DropdownItem key="analysis" title={t("analyze")} endContent={<WebIcon name={"scan"} />} />
                    )
                }
                {
                    cvQAOn && (
                        <DropdownItem key="questions" title={t("questionsAndAnswers")} endContent={<WebIcon name={"question_mark"} />} />
                    )
                }
                {
                    cvSalaryOn && (
                        <DropdownItem key="salary" title={t("avgSalary")} endContent={<WebIcon name={"payments"} />} />
                    )
                }
            </DropdownMenu>
        </Dropdown>
    )
}

export default WebAICVButton