import { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Input, Listbox, ListboxItem } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"
import { updateProfile, EmailAuthProvider, sendPasswordResetEmail, linkWithCredential } from 'firebase/auth';
import { getBoolean } from 'firebase/remote-config';
import { httpsCallable } from 'firebase/functions';

import { regex } from 'cvpop-constants-sdk';
import { setUser } from 'cvpop-redux-sdk'
import { getEnglishLabelFromLanguageCode } from 'cvpop-utility-sdk';
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { useColorScheme } from '../../../controllers/hooks';
import { auth, functions, remoteConfig } from '../../../controllers/firebase';
import { errorHandler } from '../../../controllers/app';

import { AlertContext } from '../../../contexts/AlertContext';

import WebIcon from '../../atoms/WebIcon';
import WebListItemHeaderView from '../../molecules/views/WebListItemHeaderView';


const WebIntroCommonItemInput = ({ type }) => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch();
    const n = useNavigate()
    const cs = useColorScheme()
    const fbuser = auth.currentUser;

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const openAIOn = useMemo(() => (getBoolean(remoteConfig, "openAIOn")), [])
    const userJobTitleTipOn = useMemo(() => (getBoolean(remoteConfig, "userJobTitleTip")), [])

    const api = useMemo(() => httpsCallable(functions, "getJobTitleTip"), []);

    // ------------------------------------------------------------------------------------------------------
    const title = useMemo(() => {
        if (type === "first-name") return t("introOurselves")
        if (type === "last-name") return `${t("hello")} ${user.firstName}, ${t("whatsYourSurname")}`
        if (type === "job-title") return `${t("niceToMeetYou")} ${user.firstName}!`
        if (type === "email") return t("introEmailTitle")
        if (type === "phone") return t("introPhoneTitle")
    }, [type, user])

    const message = useMemo(() => {
        if (type === "job-title") return t("whatsYourJobTitle")
        if (type === "email") return t("introEmailMsg")
        if (type === "phone") return t("introPhoneMsg")
    }, [type])

    const autoCapitalize = useMemo(() => type === "email" ? "none" : "words", [type])

    const placeholder = useMemo(() => {
        if (type === "first-name") return t("whatsYourName")
        if (type === "last-name") return t("lastNamePh")
        if (type === "job-title") return t("jobTitlePh")
        if (type === "email") return t("emailPh")
        if (type === "phone") return t("introPhoneTitle")
    }, [type])

    const initialValue = useMemo(() => {
        if (type === "first-name") return user.firstName || ""
        if (type === "last-name") return user.lastName || ""
        if (type === "job-title") return user.professionalTitle || ""
        if (type === "email") return user.email || ""
        if (type === "phone") return user.phone || ""
    }, [type, user.email, user.firstName, user.lastName, user.phone, user.professionalTitle])

    const nextScreenPath = useMemo(() => {
        if (type === "first-name") return `/app/${platform}/onboarding/last-name`
        if (type === "last-name") return `/app/${platform}/onboarding/job-title`
        if (type === "job-title") return `/app/${platform}/onboarding/new-profile`
        if (type === "email") return `/app/${platform}/onboarding/phone`
        if (type === "phone") return `/app/${platform}/onboarding/location`
    }, [type, platform])

    // ------------------------------------------------------------------------------------------------------
    const [text, setText] = useState(initialValue)
    const [loading, setLoading] = useState(false)
    const [jobTitleTip, setJobTitleTip] = useState(null)

    // ------------------------------------------------------------------------------------------------------
    const setInput = useCallback(async () => {

        let revertAnony = false
        if (fbuser.isAnonymous && type === "email") {
            setLoading(true)
            const cred = EmailAuthProvider.credential(text, (Math.random() + 1).toString(36).substring(2));
            try {
                await linkWithCredential(auth.currentUser, cred)
                sendPasswordResetEmail(auth, text).catch(e => errorHandler(alert, "err_send_reset_pwd", e, true))
                revertAnony = true
                setLoading(false)
            } catch (e) {
                setLoading(false)
                if (e.code === "auth/email-already-in-use")
                    return alert(t("authLinkEmailAlreadyInUseTitle"), t("authLinkEmailAlreadyInUseMsg"))
                        .then(() => d(setUser({ user: produce(user, d => { d.email = text }) })))
                        .catch(() => { })
                        .finally(() => n(`/app/${platform}/sign`))
                errorHandler(alert, "err_link_credential_user", e, true)
            }
        }

        d(setUser({
            user: produce(user, d => {
                if (type === "first-name") d.firstName = text
                if (type === "last-name") d.lastName = text
                if (type === "job-title") d.professionalTitle = text
                if (type === "email") d.email = text
                if (type === "phone") d.phone = text
                if (revertAnony) d.isAnonymous = false
            })
        }))

        if (openAIOn && userJobTitleTipOn && text !== user.professionalTitle && type === "job-title") {
            setLoading(true)
            try {
                const { data } = await api({ jobTitle: text, hLng: getEnglishLabelFromLanguageCode(language) })
                if (data?.isValid === false) {
                    setJobTitleTip(data)
                    return onOpen()
                }
            }
            catch (e) { errorHandler(alert, "err_get_user_job_title_tip", e, true) }
            finally { setLoading(false) }
        }

        if (type === "first-name" && text !== fbuser?.displayName)
            updateProfile(fbuser, { displayName: text }).catch(e => errorHandler(alert, "err_set_account_name", e, true))

        n(nextScreenPath)

    }, [type, fbuser, user, text, nextScreenPath, n, d, alert])

    const isDisabled = useCallback(() => {
        if (type === "email") return (_.isEmpty(text) || !((text || "").toLowerCase().match(regex.mail)))
        return _.isEmpty(text)
    }, [type, text])

    const onSubmitEditing = useCallback(() => {
        if (type === "email") return text && text.toLowerCase().match(regex.mail) && setInput()
        text && setInput()
    }, [type, setInput, text])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className=' bg-content1 flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <div className='max-w-md min-w-96 m-3'>

                {
                    (type !== "first-name" && platform !== "web") && (
                        <Button isIconOnly variant={"light"} className='mb-3' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }

                <h1 className='text-3xl mb-3 font-bold'>
                    {title}
                </h1>

                {
                    message && (
                        <h1 className='text-xl mb-3'>
                            {message}
                        </h1>
                    )
                }

                <Input
                    defaultValue={initialValue}
                    size={"lg"}
                    autoFocus
                    isClearable
                    autoCapitalize={autoCapitalize}
                    value={text}
                    onChange={({ target }) => setText(target.value)}
                    onKeyDown={({ key }) => (key === "Enter" && onSubmitEditing())}
                    placeholder={placeholder}
                />

                <Button
                    color={"primary"}
                    className='mt-3'
                    isLoading={loading}
                    isDisabled={isDisabled()}
                    fullWidth
                    onPress={() => setInput()}
                >
                    {t('continue')}
                </Button>

                {
                    (type === "email" || type === "phone") && (
                        <Button isDisabled={loading} className='mt-3' color='primary' fullWidth variant={"light"} onClick={() => n(nextScreenPath)}>
                            {t("skip")}
                        </Button>
                    )
                }
            </div>

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton={true}
                size={"2xl"}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent className='bg-background'>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t("canGiveAdvice")}</ModalHeader>
                            <ModalBody className="gap-0">
                                <p>{jobTitleTip.explanation}</p>
                                <WebListItemHeaderView text={t("useInstead")} />
                                <Card shadow={"none"}>
                                    <CardBody className="p-2 ">
                                        <Listbox
                                            aria-label="Actions"
                                            variant={"flat"}
                                            onAction={k => {
                                                d(setUser({ user: produce(user, d => { d.professionalTitle = jobTitleTip.suggestions[k] }) }))
                                                setText(jobTitleTip.suggestions[k])
                                                onClose()
                                            }}
                                        >
                                            {
                                                jobTitleTip.suggestions.map((s, i) => (
                                                    <ListboxItem key={i} title={s} endContent={(<WebIcon name={"chevron_right"} />)} />
                                                ))
                                            }
                                        </Listbox>
                                    </CardBody>
                                </Card>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={() => { onClose(); n(nextScreenPath); }}>
                                    {t("noThanks")}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
};

export default WebIntroCommonItemInput