import { useCallback, useMemo, useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, setUserLogged } from 'cvpop-redux-sdk'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Cropper } from 'react-advanced-cropper';
import { Card, CardHeader, CardBody, CardFooter, Input, Button, Avatar, Spinner, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { updateProfile, updatePassword, updateEmail, reauthenticateWithCredential, EmailAuthProvider, signOut, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { produce } from "immer"

import { icons } from 'cvpop-assets-sdk';
import { getUnsubcribeUserUrl, getAvatarUser, ee } from 'cvpop-utility-sdk'
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { errorHandler, imageToBase64 } from "../../../controllers/app"
import { auth, db } from '../../../controllers/firebase'
import { deleteCv } from '../../../controllers/cv'
import { useColorScheme } from '../../../controllers/hooks';
import { AlertContext } from '../../../contexts/AlertContext';

import 'react-advanced-cropper/dist/style.css'


const WebAccount = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user, userPro } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { alert, prompt, confirm, confirmDelete } = useContext(AlertContext)
    const { runDeleteAccount, runUpdateEmail } = useLocation().state || {}

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const cs = useColorScheme()
    const inputRef = useRef()
    const cropRef = useRef()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const [cropImg, setCropImg] = useState("")
    const [oldPwd, setOldPwd] = useState("");
    const [newPwd, setNewPwd] = useState("");
    const [repeatPwd, setRepeatPwd] = useState("");
    const [loadingChangePwd, setLoadingChangePwd] = useState(false)
    const [loadingForgotPwd, setLoadingForgotPwd] = useState(false)
    const [loadingLogout, setLoadingLogout] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingVerifyEmail, setLoadingVerifyEmail] = useState(false);
    const [loadingUpdateEmail, setLoadingUpdateEmail] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
    const { displayName, email, emailVerified, isAnonymous } = fbuser || {}; //in case of logout
    const { isAms, isMarketing, firstName, lastName, professionalTitle, location, planSubscriptionState } = user || {}

    // ------------------------------------------------------------------------------------------------------
    const avatar = useMemo(() => getAvatarUser(user, platform) || icons.avatar, [user, platform])
    const changePwdOn = useMemo(() => user && !user.isAnonymous && fbuser.providerData.find(e => e.providerId === "password"), [user])

    // ------------------------------------------------------------------------------------------------------------------------
    const runLogout = useCallback(() => {

        setLoadingLogout(true)
        confirm(t("logout"), t("logoutMsg"))
            .then(() => signOut(auth))
            .then(() => {
                d(setUserLogged(false))
                d(setUser({ user: null, ignoreListener: true }))
                n(`/app/${platform}/onboarding`)
            })
            .catch(e => errorHandler(alert, "err_logout", e))
            .finally(() => setLoadingLogout(false))

    }, [d, n])

    const runUpdateUserEmail = useCallback(async () => {

        let email = ""
        try { email = (await prompt(t("updateEmailTitle"), t("updateEmailMsg"), t("update")) || "").trim().toLowerCase() } catch (e) { return }
        setLoadingUpdateEmail(true)
        updateEmail(auth.currentUser, email)
            .then(() => d(setUser({ user: produce(user, d => { d.email = email }) })))
            .catch(e => errorHandler(alert, "err_update_user_email", e))
            .finally(() => setLoadingUpdateEmail(false))

    }, [user])

    const runDeleteUser = useCallback(async () => {

        if (isAms || isMarketing) return alert("DEV: Not allowed");

        try { await confirm(t('areYouSure'), t('deleteDataLost'), t('continue'), null, "danger"); } catch (e) { return }

        setLoadingDelete(true)

        try {

            const uid = fbuser.uid

            const [interviewSnap, quizSnap, cvSnap] = await Promise.all([
                getDocs(query(collection(db, "00_INTERVIEWS_00"), where("uid", "==", uid))),
                getDocs(query(collection(db, "00_QUIZES_00"), where("uid", "==", uid))),
                getDocs(query(collection(db, "00_RESUMES_00"), where("Metadata.uid", "==", uid))),
            ])

            interviewSnap.docs.forEach(async doc => await deleteDoc(doc(db, "00_INTERVIEWS_00", doc.id)));
            quizSnap.docs.forEach(async doc => await deleteDoc(doc(db, "00_QUIZES_00", doc.id)));
            cvSnap.docs.forEach(async doc => await deleteCv(doc.data(), true));

            deleteDoc(doc(db, "00_FCM_TOKENS_00", uid)).catch(e => errorHandler(alert, "err_delete_all_fcm_toket", e, true))

            await deleteDoc(doc(db, "00_USERS_00", uid))

            await fbuser.delete()
            d(setUser({ user: null, ignoreListener: true }))
            setLoadingDelete(false)
            n(`/app/${platform}/onboarding`)

        } catch (e) {
            errorHandler(alert, "err_delete_account", e)
            setLoadingDelete(false)
        }

    }, [fbuser]);

    const runUpdatePwd = useCallback(async () => {

        setLoadingChangePwd(true)
        reauthenticateWithCredential(fbuser, EmailAuthProvider.credential(fbuser.email, oldPwd))
            .then(() => updatePassword(fbuser, newPwd))
            .then(() => alert(t('pwdUpdated'), t('pwdUpdatedMsg')))
            .catch(e => errorHandler(alert, "err_update_pwd", e))
            .finally(() => {
                setOldPwd("")
                setNewPwd("")
                setRepeatPwd("")
                setLoadingChangePwd(false)
            })

    }, [newPwd, oldPwd, repeatPwd, n])

    const runForgotPwd = useCallback(() => {

        setLoadingForgotPwd(true)
        sendPasswordResetEmail(auth, email)
            .then(() => alert(t('forgotPwdSentTitle'), t('forgotPwdSentMsg')))
            .catch(e => errorHandler(alert, "err_forgot_pwd", e))
            .finally(() => setLoadingForgotPwd(false))

    }, [email]);

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (runDeleteAccount) setTimeout(() => runDeleteUser(), [1000])
    }, [runDeleteAccount])

    useEffect(() => {
        if (runUpdateEmail) setTimeout(() => runUpdateUserEmail(), [1000])
    }, [runUpdateEmail])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8 p-8">
            <Card>
                <CardHeader>
                    {t("profile")}
                </CardHeader>
                <CardBody>
                    <div className='flex'>
                        <div className='self-center m-3'>
                            <Avatar
                                isBordered
                                onClick={() => {
                                    if (!inputRef.current) return
                                    inputRef.current.value = ""
                                    inputRef.current.click()
                                }}
                                src={avatar}
                            />
                        </div>
                        <div className='w-full'>
                            <Input
                                placeholder={t('name')}
                                value={firstName || displayName || ""}
                                className='mt-2'
                                isReadOnly={true}
                                style={{ cursor: "pointer" }}
                                onClick={async () => {
                                    try {
                                        const txt = (await prompt(t('updateFirstNameTitle'), t('updateFirstNameMsg'), t('update'), null, null, firstName || displayName || "") || "").trim()
                                        if (!txt) return
                                        d(setUser({ user: produce(user, d => void _.set(d, "firstName", txt)) }))
                                        updateProfile(fbuser, { displayName: txt }).catch(e => errorHandler(alert, "err_update_usr_name", e, true))
                                    } catch (e) { return }
                                }}
                            />
                            <Input
                                placeholder={t('surname')}
                                value={lastName || ""}
                                className='mt-2'
                                style={{ cursor: "pointer" }}
                                isReadOnly={true}
                                onClick={async () => {
                                    try {
                                        const txt = (await prompt(t('updateLastNameTitle'), t('updateLastNameMsg'), t('update'), null, null, lastName || "") || "").trim()
                                        if (!txt) return
                                        d(setUser({ user: produce(user, d => void _.set(d, "lastName", txt)) }))
                                    } catch (e) { return }
                                }}
                            />
                        </div>
                    </div>

                    <Input
                        placeholder={t('jobTitle')}
                        value={professionalTitle || ""}
                        className='mt-2'
                        style={{ cursor: "pointer" }}
                        isReadOnly={true}
                        onClick={async () => {
                            try {
                                const txt = (await prompt(t('jobTitle'), t('updateJobTitle'), t('update'), null, null, professionalTitle || "") || "").trim()
                                if (!txt) return
                                d(setUser({ user: produce(user, d => void _.set(d, "professionalTitle", txt)) }))
                            } catch (e) { return }
                        }}
                    />
                    {/* <Input placeholder={t('location')} defaultValue={location || ""} className='mt-2' /> */}
                    {email && (
                        <div className='flex'>
                            <Input
                                value={email}
                                isReadOnly={true}
                                className='mt-2'
                                style={{ cursor: "pointer" }}
                                onClick={() => n(`/app/${platform}/reauth-update-email`)}
                            />
                            {loadingUpdateEmail && (<Spinner size={"sm"} className='m-2' />)}
                        </div>
                    )}
                </CardBody>
                <CardFooter>
                    {
                        !emailVerified && !isAnonymous && (
                            <Button
                                fullWidth
                                isLoading={loadingVerifyEmail}
                                color={"warning"}
                                onClick={() => {
                                    setLoadingVerifyEmail(true)
                                    confirm(t('verifyEmail'), t('verifyEmailMsg'))
                                        .then(() => sendEmailVerification(fbuser))
                                        .then(() => alert(t('verifyEmailSent'), t('verifyEmailSentMsg')))
                                        .catch(e => errorHandler(alert, "err_validate_email", e))
                                        .finally(() => setLoadingVerifyEmail(false))
                                }}
                            >
                                {t('verifyEmail')}
                            </Button>
                        )
                    }
                </CardFooter>
            </Card>

            {
                changePwdOn && (
                    <Card>
                        <CardHeader>
                            {t("changePwd")}
                        </CardHeader>
                        <CardBody>
                            <Input type="password" placeholder={t('currentPwd')} onChange={({ target }) => setOldPwd(target.value)} className='mt-2' />
                            <Input type="password" placeholder={t('newPwd')} onChange={({ target }) => setNewPwd(target.value)} className='mt-2' />
                            <Input type="password" placeholder={t('newPwdRepeat')} onChange={({ target }) => setRepeatPwd(target.value)} className='mt-2' />
                        </CardBody>
                        <CardFooter className='flex-col'>
                            <Button
                                fullWidth
                                isLoading={loadingChangePwd}
                                isDisabled={!newPwd || !repeatPwd || !oldPwd || (newPwd !== repeatPwd)}
                                color={"primary"}
                                onClick={runUpdatePwd}
                            >
                                {t("changePwd")}
                            </Button>
                            <Button
                                fullWidth
                                isLoading={loadingForgotPwd}
                                color={"secondary"}
                                onClick={runForgotPwd}
                                className='mt-3'
                            >
                                {t("forgotPwdQuestion")}
                            </Button>
                        </CardFooter>
                    </Card>
                )
            }


            <Card>
                <CardHeader>
                    {t("account")}
                </CardHeader>
                <CardFooter className='flex-col'>

                    {
                        userPro && (
                            <Button
                                fullWidth
                                color={"secondary"}
                                onClick={() => {
                                    if (platform === "macos" || platform === "visionos")
                                        return window.webkit.messageHandlers["openUrl"].postMessage(getUnsubcribeUserUrl(user));

                                    window.open(getUnsubcribeUserUrl(user), "_blank")
                                }}
                            >
                                {t('manageSubscription')}
                            </Button>
                        )
                    }

                    {
                        !isAnonymous && (
                            <Button fullWidth color='primary' isLoading={loadingLogout} onClick={runLogout} className='mt-3'>{t("logout")}</Button>
                        )
                    }

                    <Button
                        fullWidth
                        color={"danger"}
                        isLoading={loadingDelete}
                        className='mt-3'
                        onPress={() => confirmDelete().then(() => isAnonymous ? runDeleteUser() : n(`/app/${platform}/reauth-delete-account`)).catch(() => { })}
                    >
                        {t('deleteAccount')}
                    </Button>

                </CardFooter>

            </Card>

            <input
                type="file"
                className='hidden'
                accept="image/png,image/jpeg"
                ref={inputRef}
                onChange={({ target }) => {
                    if (target.files.length === 0) return
                    if (!["image/png", "image/jpeg"].includes(target.files[0].type)) return alert(t("errTitle"), t("notSupportedTypeFile"))
                    imageToBase64(target.files[0])
                        .then(b64 => {
                            setCropImg(b64);
                            onOpen();
                        })
                        .catch(e => errorHandler(alert, "err_convert_base64", e))
                }}
            />

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton={true}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>

                    <ModalBody>
                        <Cropper ref={cropRef} src={cropImg} aspectRatio={0.75} />
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose} >
                            {t("cancel")}
                        </Button>
                        <Button
                            color="primary"
                            onPress={() => {
                                if (!cropRef.current) return
                                const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
                                d(setUser({ user: produce(user, d => { d.avatar = croppedImage.split(',')[1] }) }));
                                onClose()
                            }}
                        >
                            {t("done")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div >
    )

}

export default WebAccount