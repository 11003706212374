import { useRef, useEffect, useState, useMemo, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { doc, onSnapshot } from 'firebase/firestore';

import _ from "lodash"

import { renderCvPreview } from "cvpop-utility-sdk"
import { setCv } from "cvpop-redux-sdk";

import { db } from "../../../controllers/firebase";
import { errorHandler } from "../../../controllers/app";

import { AlertContext } from "../../../contexts/AlertContext";

import WebHeader from "../../molecules/views/WebHeader";
import WebNavbar from "../../atoms/WebNavbar";
import WebCVSidebar from "../../organisms/sidebars/WebCVSidebar";
import WebCVBreadcrumb from "../../organisms/breadcrumbs/WebCVBreadcrumb";


const WebCVOverview = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const ref = useRef()
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const [frameScale, setFrameScale] = useState(1)

    // ------------------------------------------------------------------------------------------------------------------------
    const isWeb = useMemo(() => platform === "web", [platform])

    const addStyle = useMemo(() => (`.page{ transition: transform 0.3s ease; transform-origin: 0 0; transform: scale(${frameScale}) }`), [frameScale])
    const html = useMemo(() => renderCvPreview(cv, user, addStyle).html, [cv, user, addStyle])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        const sidebarWidth = 128 // 8rem = 128px
        const leftPanelWidth = ref.current?.offsetWidth || 0
        const cvPanelWidth = document.body.offsetWidth - (sidebarWidth + leftPanelWidth)
        const scale = (cvPanelWidth - 50) / (760) > 1 ? 1 : (cvPanelWidth - 50) / (760)

        setFrameScale(scale)
        window.addEventListener('resize', () => {
            const leftPanelWidth = ref.current?.offsetWidth || 0
            const cvPanelWidth = document.body.offsetWidth - (sidebarWidth + leftPanelWidth)
            const scale = (cvPanelWidth - 50) / (760) > 1 ? 1 : (cvPanelWidth - 50) / (760)
            setFrameScale(scale)
        });
        return () => window.removeEventListener('resize', () => { });
    }, [ref]);


    useEffect(() => {
        if (!cv) return
        const unsub = onSnapshot(doc(db, "00_RESUMES_00", cv.Metadata.id), doc =>
            doc.exists() ? !_.isEqual(cv, doc.data()) && d(setCv({ cv: doc.data(), ignoreListener: true })) : n(`/app/${platform}/home/cv-list`),
            e => errorHandler(alert, "err_cv_listener", e, true)
        );
        return () => unsub();
    }, [cv?.Metadata?.id, n])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="h-screen">
            <WebNavbar />

            <div className="flex" >
                <WebCVSidebar />

                <div className="ml-32 w-full">
                    <WebCVBreadcrumb />

                    <div className="grid grid-cols-6" style={{ height: isWeb ? `calc(100vh - 105px)` : "100vh" }}>

                        <div ref={ref} className="col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-2 2xl:col-span-2 overflow-y-auto overscroll-none" style={{ height: isWeb ? `calc(100vh - 105px)` : "100vh" }}>
                            <Outlet />
                        </div>

                        <div className="hidden md:block  md:col-span-3 lg:col-span-3 xl:col-span-4 2xl:col-span-4 border-l border-divider" style={{ height: isWeb ? `calc(100vh - 105px)` : "100vh" }}>
                            <WebHeader title={cv.Metadata.name || ""} position="static" />
                            <div
                                dangerouslySetInnerHTML={{ __html: html }}
                                className="overflow-y-auto overflow-x-hidden overscroll-none"
                                style={{ padding: 25, height: isWeb ? `calc(100vh - 105px)` : `calc(100vh - 6rem)` }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )

}

export default WebCVOverview