import React, { useCallback, useState, useMemo, useContext } from "react";
import { Accordion, AccordionItem, Button, Input, Select, SelectItem } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { cvPickerItems } from "cvpop-constants-sdk";
import { setCv } from "cvpop-redux-sdk";
import { illustrations } from "cvpop-assets-sdk";

import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { createFbId } from "../../../../controllers/app";
import { AlertContext } from "../../../../contexts/AlertContext";
import { useColorScheme } from "../../../../controllers/hooks";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";


const WebContactInfo = ({ elType }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const key = useMemo(() => elType === "Telephone" ? "phones" : elType === "Website" ? "websites" : elType === "SocialAccount" ? "socialAccount" : "instMessages", [elType])
    const itemKey = useMemo(() => elType === "Telephone" ? "phone" : elType === "Website" ? "website" : key, [elType, key])
    const itemList = useMemo(() => (cv.SkillsPassport.LearnerInfo.Identification.ContactInfo[elType] || []), [cv, elType])

    // ------------------------------------------------------------------------------------------------------
    const [itemSelectedKeys, setItemSelectedKeys] = useState([]);

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {
        const Id = createFbId()
        editCv(
            produce(cv, d => {
                d.SkillsPassport.LearnerInfo.Identification.ContactInfo[elType].push({ Id, Contact: "", Use: { Code: "", Label: "" } })
            })
        )
        setItemSelectedKeys([Id])
    }, [cv])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.Identification.ContactInfo[elType].filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.Identification.ContactInfo[elType] = newList
            })
        )
    }, [cv])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t(elType === "Telephone" ? "phones" : elType === "Website" ? "websites" : elType === "InstantMessaging" ? "instMessages" : "socialAccount")} />
            <div className="p-5">
                {
                    itemList.length === 0 && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations[(elType).toLowerCase().replaceAll('_', '')] }}
                                title={t(elType === "Telephone" ? "phones" : elType === "Website" ? "websites" : elType === "InstantMessaging" ? "instMessages" : "socialAccount")}
                                message={t("emptyListMsg")}
                            >
                                <Button fullWidth color={"primary"} className="mt-6" onClick={addItem}>{t("add")}</Button>
                            </WebCenterView>
                        </div>
                    )
                }
                <Accordion variant="splitted" selectedKeys={itemSelectedKeys} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {
                        itemList.map(({ Id, Contact, Use }, i) => (
                            <AccordionItem
                                isCompact
                                onPress={() => setItemSelectedKeys((itemSelectedKeys[0] || "") === Id ? [] : [Id])}
                                classNames={{ base: "shadow-none", title: "text-small" }}
                                title={Contact || t("elementWithNoTitle")}
                                key={Id}
                            >
                                <Select
                                    label={t('type')}
                                    defaultSelectedKeys={[Use.Code]}
                                    disallowEmptySelection
                                    textValue={t(Use.Label)}
                                    className="mt-4"
                                    classNames={{ popoverContent: `${cs} text-foreground` }}
                                    onChange={({ target }) => {
                                        const e = cvPickerItems[`contactInfo${elType}`]().find(e => e.code === target.value)
                                        editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Identification.ContactInfo["${elType}"][${i}].Use`, { Code: e.code, Label: e.code })));
                                    }}
                                >
                                    {cvPickerItems[`contactInfo${elType}`]().map(el => (<SelectItem key={el.code} value={el.code}>{t(el.label)}</SelectItem>))}
                                </Select>
                                <Input
                                    label={t(itemKey)}
                                    value={Contact}
                                    onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Identification.ContactInfo["${elType}"][${i}].Contact`, target.value)))}
                                    className="mt-4"
                                />

                                <Button
                                    fullWidth
                                    color={"danger"}
                                    className="mt-4 mb-2"
                                    onPress={() => confirmDelete().then(() => deleteItem(Id)).catch(() => { })}
                                >
                                    {t("delete")}
                                </Button>

                            </AccordionItem>
                        ))
                    }
                </Accordion>

                {itemList.length > 0 && (
                    <Button
                        fullWidth
                        color={"primary"}
                        variant={"ghost"}
                        className="mt-3"
                        onClick={addItem}
                    >
                        {t("addNewItem")}
                    </Button>
                )}

            </div>

        </div>
    )
}

export default WebContactInfo