import React, { useCallback } from "react";
import { Card, CardBody, Input, Select, SelectItem } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { cvPickerItems } from "cvpop-constants-sdk";
import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { useColorScheme } from "../../../../controllers/hooks";

import WebHeader from "../../../molecules/views/WebHeader";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebHeadline = ({ }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { Headline } = cv.SkillsPassport.LearnerInfo
    const { Description = {}, Type = {} } = Headline

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelHeadline")} />
            <div className="p-5">

                <Card shadow={"none"}>
                    <CardBody>
                        <Select
                            label={t('cvLabelHeadline')}
                            defaultSelectedKeys={[Type?.Code || ""]}
                            className="mt-2"
                            classNames={{ popoverContent: `${cs} text-foreground` }}
                            onChange={({ target }) => {
                                const e = cvPickerItems.headline().find(e => e.code === target.value)
                                editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Headline.Type", { Code: e.code, Label: e.code })))
                            }}
                        >
                            {cvPickerItems.headline().map(el => (<SelectItem key={el.code} value={el.code}>{t(el.label)}</SelectItem>))}
                        </Select>
                        <Input
                            label={t("description")}
                            value={Description?.Label}
                            onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Headline.Description.Label", target.value)))}
                            className="mt-3"
                        />
                    </CardBody>
                </Card>

                <WebAIAssistantButton sectionId={"HEADLINE"} />
            </div>
        </div>

    )
}

export default WebHeadline