import { useContext, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Button, semanticColors } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { produce } from "immer"

import { setCv } from "cvpop-redux-sdk";
import { illustrations } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk"

import SignatureCanvas from 'react-signature-canvas'

import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebSignature = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    const { confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()
    const ref = useRef()

    // ------------------------------------------------------------------------------------------------------
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    // ------------------------------------------------------------------------------------------------------
    const { Data } = cv.SkillsPassport.LearnerInfo.Identification.Signature || {}

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>

            <WebHeader title={t("signature")} />
            <div className="p-5">
                <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                    <WebCenterView
                        imageProps={{
                            src: Data ? Data : illustrations.signature,
                            style: Data ? { transform: "rotate(90deg)", maxHeight: 200, margin: "0 auto", backgroundColor: "white" } : {},
                            className: `${(Data) ? "bg-white" : ""} rounded`
                        }}
                        title={t("featSignature")}
                        message={t("featSignatureMsg")}
                    >
                        {
                            !Data ? (
                                <Button color="primary" className="mt-6" onPress={onOpen} >
                                    {t("add")}
                                </Button>
                            ) : (
                                <Dropdown backdrop="blur" className={`${cs} text-foreground`}>
                                    <DropdownTrigger>
                                        <Button color="primary" className="mt-6" >
                                            {t("showOptions")}
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        aria-label="Static Actions"
                                        color={"primary"}
                                        onAction={k => {
                                            if (k === "delete")
                                                return confirmDelete()
                                                    .then(() => d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.Signature = { Data: "" } }) })))
                                                    .catch(() => { })
                                            if (k === "edit")
                                                return onOpen()

                                        }}
                                    >
                                        <DropdownItem key="edit" title={t("edit")} endContent={<WebIcon name={"edit"} />} />
                                        <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                                    </DropdownMenu>
                                </Dropdown>
                            )
                        }

                        <WebAIAssistantButton color={"secondary"} sectionId={"SIGNATURE"} />

                    </WebCenterView>
                </div>


                <Modal
                    isOpen={isOpen}
                    onOpenChange={onOpenChange}
                    backdrop={"blur"}
                    className={`${cs} text-foreground`}
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1">{t("signature")}</ModalHeader>
                                <ModalBody className="mx-4 rounded" style={{ backgroundColor: semanticColors.light.default[200] }}>
                                    <SignatureCanvas ref={ref} />
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        onClick={() => ref.current?.clear()}
                                    >
                                        {t("restore")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        onPress={() => {
                                            if (!ref.current) return
                                            const canvas = ref.current.getCanvas()

                                            const newCanvas = document.createElement('canvas');
                                            const newCtx = newCanvas.getContext('2d');

                                            newCanvas.width = canvas.height;
                                            newCanvas.height = canvas.width;

                                            newCtx.translate(newCanvas.width / 2, newCanvas.height / 2);
                                            newCtx.rotate(-90 * Math.PI / 180);
                                            newCtx.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);

                                            const rotateImg = newCanvas.toDataURL("image/png")
                                            d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.Signature = { Data: rotateImg } }) }))
                                            onClose()
                                        }}
                                    >
                                        {t("done")}
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </div>
        </div>
    )
}

export default WebSignature