const { cvPickerItems } = require("cvpop-constants-sdk")
const { t } = require("cvpop-localization-sdk")

const { sectionTitle } = require("./section-title")
const { sectionSubtitle } = require("./section-subtitle")
const { sectionDescription } = require("./section-description")

module.exports = {
    achievements: cv => {

        const { Metadata } = cv
        const { Achievement = [] } = cv.SkillsPassport.LearnerInfo

        const locale = Metadata.lng === 'no' ? 'nb' : Metadata.lng

        if ((Achievement || []).filter(i => i.Title.Code !== "signature_equivalent").length === 0) return ""

        return `
        <div class="achievement-container container">
            ${sectionTitle(cv, "cvLabelAchievement", "Achievement", "fas fa-info-circle")}

            ${(Achievement || []).filter(i => i.Title.Code !== "signature_equivalent").map(({ Title, Description }) => `
                <div class="achievement-content content">
                    ${Title.Code === "" ? "" : cvPickerItems.addInfoTitle().find(o => o.code === Title.Code) ?
                sectionSubtitle("addInfo", t(cvPickerItems.addInfoTitle().find(o => o.code === Title.Code).label, { locale })) :
                sectionSubtitle("addInfo", Title.Label)
            }
                    ${sectionDescription(Description)}
                </div>
            `).join("")}
        </div>
        `
}
}