import { useCallback, useState, useMemo, useRef, useContext } from "react";
import { Accordion, AccordionItem, Button, Input, Switch, ButtonGroup } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";
import { Cropper } from 'react-advanced-cropper';

import { icons, illustrations } from "cvpop-assets-sdk"
import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { createFbId, errorHandler, goToPaywall, imageToBase64 } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebEditor from "../../../atoms/WebEditor";
import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebImageSearchModal from "../../../molecules/modals/WebImageSearchModal";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const FoundamentalItem = ({ item, index, elType }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert, confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const cropRef = useRef()
    const cropModal = useDisclosure();
    const searchModal = useDisclosure();


    // ------------------------------------------------------------------------------------------------------
    const isWork = useMemo(() => elType === "WorkExperience", [elType])
    const logoItem = useMemo(() => item[isWork ? "Employer" : "Organisation"].Logo, [cv])

    // ------------------------------------------------------------------------------------------------------
    const [cropImg, setCropImg] = useState("")

    // ------------------------------------------------------------------------------------------------------
    const { Id, Period, Employer, Title, Organisation, Position, Activities } = item

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const onLogoAction = useCallback(k => {

        if (k === "upload") {
            if (!inputRef.current) return
            inputRef.current.value = ""
            return inputRef.current.click()
        }

        if (k === "search")
            return searchModal.onOpen()

        if (k === "delete")
            return confirmDelete()
                .then(() => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].${isWork ? "Employer" : "Organisation"}.Logo`, null))))
                .catch(() => { })

    }, [cv, inputRef, elType, index, isWork])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo[elType].filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo[elType] = newList
            })
        )
    }, [cv, elType])

    // ------------------------------------------------------------------------------------------------------
    const renderCoreLogo = useCallback(() => (
        <div className="relative">
            <img
                onClick={!userPro ? () => goToPaywall(offering, platform, n) : null}
                src={logoItem && userPro ? `data:${logoItem.MimeType};base64,${logoItem.Data}` : icons.phcompanylogo}
                className="object-contain rounded-lg"
                style={{ width: 150 }}
            />

            {
                !userPro && (
                    <div className='absolute bg-yellow-500 w-8 h-8 flex justify-center items-center shadow-2xl bottom-1 right-1 rounded-full'>
                        <WebIcon name={"workspace_premium"} className={"filled text-white"} />
                    </div>
                )
            }
        </div>
    ), [logoItem, userPro, offering, platform, n])

    // ------------------------------------------------------------------------------------------------------
    return (
        <>
            <p className="text-xs text-default-600 font-bold uppercase ml-3 mb-1.5">{t("period")}</p>
            <div className="flex">
                <Input
                    label={t("from")}
                    type={"date"}
                    className="mr-2"
                    value={Period.From.Year > 0 ? `${Period.From.Year}-${Period.From.Month < 10 ? "0" : ""}${Period.From.Month}-${Period.From.Day < 10 ? "0" : ""}${Period.From.Day}` : undefined}
                    onChange={({ target }) => {
                        const jsDate = new Date(target.value)
                        const cvDate = (target.value && jsDate.getFullYear() > 1900) ?
                            { Day: jsDate.getDate(), Month: jsDate.getMonth() + 1, Year: jsDate.getFullYear() } :
                            { Day: null, Month: null, Year: null }
                        editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].Period.From`, cvDate)))
                    }}
                />

                {
                    !Period.Current && (
                        <Input
                            label={t("to")}
                            type={"date"}
                            className="mr-2"
                            value={Period.To.Year > 0 ? `${Period.To.Year}-${Period.To.Month < 10 ? "0" : ""}${Period.To.Month}-${Period.To.Day < 10 ? "0" : ""}${Period.To.Day}` : undefined}
                            onChange={({ target }) => {
                                const jsDate = new Date(target.value)
                                const cvDate = (target.value && jsDate.getFullYear() > 1900) ?
                                    { Day: jsDate.getDate(), Month: jsDate.getMonth() + 1, Year: jsDate.getFullYear() } :
                                    { Day: null, Month: null, Year: null }
                                editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].Period.To`, cvDate)))
                            }}
                        />
                    )
                }

                <Switch
                    isSelected={Period.Current}
                    onValueChange={s => d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo[elType][index].Period.Current = s }) }))}
                >
                    {t('cvLabelInProgress')}
                </Switch>
            </div>

            <p className="text-xs text-default-600 font-bold uppercase ml-3 mb-1 mt-4">{t("employer")}</p>
            <div className="flex items-stretch">
                <div className="flex flex-col w-full mr-3">
                    <Input
                        label={t(isWork ? "companyName" : 'name')}
                        value={isWork ? Employer.Name : Organisation.Name}
                        onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].${isWork ? "Employer.Name" : "Organisation.Name"}`, target.value)))}

                    />
                    <Input
                        label={t("city")}
                        value={isWork ? Employer.ContactInfo.Address.Contact.Municipality : Organisation.ContactInfo.Address.Contact.Municipality}
                        onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].${isWork ? "Employer.ContactInfo.Address.Contact.Municipality" : "Organisation.ContactInfo.Address.Contact.Municipality"}`, target.value)))}
                        className="mt-3"
                    />
                </div>
                <div className="flex bg-background rounded-xl items-center p-3">

                    {
                        !userPro ? (
                            renderCoreLogo()
                        ) : (
                            <Dropdown>
                                <DropdownTrigger>
                                    {renderCoreLogo()}
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Static Actions" color={"primary"} onAction={onLogoAction}>
                                    <DropdownItem key="search" title={t("searchOnTheWeb")} endContent={<WebIcon name={"travel_explore"} />} />
                                    <DropdownItem key="upload" title={t("import")} endContent={<WebIcon name={"upload"} />} />
                                    {
                                        logoItem && (
                                            <DropdownItem key="delete" className="text-danger" color="danger" title={t("delete")} endContent={<WebIcon name={"delete"} />} />
                                        )
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        )
                    }

                    <input
                        type="file"
                        className='hidden'
                        accept="image/png,image/jpeg"
                        ref={inputRef}
                        onChange={({ target }) => {
                            if (target.files.length === 0) return
                            if (!["image/png", "image/jpeg"].includes(target.files[0].type)) return alert(t("errTitle"), t("notSupportedTypeFile"))
                            imageToBase64(target.files[0])
                                .then(b64 => {
                                    setCropImg(b64);
                                    cropModal.onOpen();
                                })
                                .catch(e => errorHandler(alert, "err_convert_base64", e))
                        }}
                    />

                    <Modal
                        isOpen={cropModal.isOpen}
                        onOpenChange={cropModal.onOpenChange}
                        hideCloseButton={true}
                        backdrop={"blur"}
                        size={"xl"}
                        className={`${cs} text-foreground`}
                    >
                        <ModalContent>
                            <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>
                            <ModalBody>
                                <Cropper ref={cropRef} src={cropImg} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={cropModal.onClose}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    color="primary"
                                    onPress={() => {
                                        if (!cropRef.current) return
                                        const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
                                        const newPhoto = { "MimeType": croppedImage.split(',')[0], "Data": croppedImage.split(',')[1], "Metadata": [{ "Key": "dimension", "Value": "300x400" }] }
                                        editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].${isWork ? "Employer" : "Organisation"}.Logo`, newPhoto)))
                                        cropModal.onClose()
                                    }}
                                >
                                    {t("done")}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <WebImageSearchModal
                        modal={searchModal}
                        onImageSelected={b64 => {
                            setCropImg(b64);
                            searchModal.onClose()
                            cropModal.onOpen();
                        }}
                    />

                </div>
            </div>

            <p className="text-xs text-default-600 font-bold uppercase ml-3 mb-1 mt-4">{t("qualification")}</p>
            <Input
                label={t(isWork ? 'positionHeld' : 'qualificationObtained')}
                value={isWork ? Position.Label : Title}
                onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].${isWork ? "Position.Label" : "Title"}`, target.value)))}
            />
            {
                isWork && (
                    <Input
                        label={t("cvLabelWorkSector")}
                        value={Employer.Sector ? (Employer.Sector.Label || "") : ""}
                        onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].Employer.Sector.Label`, target.value)))}
                        className="mt-3"
                    />
                )
            }

            <p className="text-xs text-default-600 font-bold uppercase ml-3 mb-1 mt-4">{t("description")}</p>
            <WebEditor
                elType={isWork ? "WORK" : "EDUCATION"}
                qualification={isWork ? Position.Label : Title}
                keyId={`${elType}${Id}`}
                value={Activities}
                onChange={t => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo[${elType}][${index}].Activities`, t === "<p><br></p>" ? "" : t)))}
            />

            <WebAIAssistantButton sectionId={isWork ? "WORK_ITEM" : "EDUCATION_ITEM"} item={item} />

            <Button
                fullWidth
                color={"danger"}
                className="my-3"
                onPress={() => confirmDelete().then(() => deleteItem(Id)).catch(() => { })}
            >
                {t("delete")}
            </Button>
        </>
    )


}


const WebFoundamentalItem = ({ elType }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)


    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const isWork = useMemo(() => elType === "WorkExperience", [elType])
    const itemList = useMemo(() => (cv.SkillsPassport.LearnerInfo[elType] || []), [cv, elType])

    const [itemSelectedKeys, setItemSelectedKeys] = useState([]);

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {

        const Id = createFbId()
        const now = new Date();
        const cvObjDate = { Year: now.getFullYear(), Month: now.getMonth() + 1, Day: now.getDate() }
        const baseItem = { Id, Activities: "", Period: { Current: false, From: cvObjDate, To: cvObjDate } }

        const emptyWork = { Position: { Label: "" }, Employer: { Name: "", ContactInfo: { Address: { Contact: { Municipality: "" } } }, Sector: { Label: "" } } }
        const emptyEdu = { Title: "", Organisation: { Name: "", ContactInfo: { Address: { Contact: { Municipality: "" } } } } }
        const emptyItem = isWork ? { ...baseItem, ...emptyWork } : { ...baseItem, ...emptyEdu }

        editCv(produce(cv, d => { d.SkillsPassport.LearnerInfo[elType].push(emptyItem) }))
        setItemSelectedKeys([Id])
    }, [cv, isWork])

    const moveItem = useCallback((a, b) => {
        const copy = (cv.SkillsPassport.LearnerInfo[elType]).slice();
        [copy[a], copy[b]] = [copy[b], copy[a]];
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo[elType] = copy }) }))
    }, [cv, elType])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t(`cvLabel${isWork ? "Experience" : 'Education'}`)} />
            <div className="p-5">
                {
                    itemList.length === 0 && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations[isWork ? "work" : "education"] }}
                                title={t(`cvLabel${isWork ? "Experience" : 'Education'}`)}
                                message={t("emptyListMsg")}
                            >
                                <Button fullWidth color={"primary"} className="mt-6" onClick={addItem}>{t("add")}</Button>
                            </WebCenterView>
                        </div>
                    )
                }

                <Accordion variant="splitted" selectedKeys={itemSelectedKeys} style={{ paddingLeft: 0, paddingRight: 0 }}>

                    {itemList.map((item, index) => (
                        <AccordionItem
                            isCompact
                            startContent={itemList.length > 1 && (
                                <ButtonGroup variant={"light"}>
                                    <Button isDisabled={index === 0} isIconOnly size={"sm"} onClick={() => moveItem(index, index - 1)}>
                                        <WebIcon name={"arrow_upward"} className={"text-base"} />
                                    </Button>
                                    <Button isDisabled={index == itemList.length - 1} isIconOnly size={"sm"} onClick={() => moveItem(index, index + 1)}>
                                        <WebIcon name={"arrow_downward"} className={"text-base"} />
                                    </Button>
                                </ButtonGroup>
                            )}
                            title={(isWork ? (item.Employer?.Name || item.Position?.Label) : (item.Title || item.Organisation?.Name)) || t("elementWithNoTitle")}
                            subtitle={isWork ? (item.Employer?.Name ? (item.Position?.Label || "") : "") : (item.Title ? (item.Organisation?.Name || "") : "")}
                            onPress={() => setItemSelectedKeys((itemSelectedKeys[0] || "") === item.Id ? [] : [item.Id])}
                            key={item.Id}
                        >
                            <FoundamentalItem item={item} index={index} elType={elType} />

                        </AccordionItem>
                    ))}
                </Accordion>

                {
                    itemList.length > 0 && (
                        <Button
                            fullWidth
                            color={"primary"}
                            variant={"ghost"}
                            className="mt-3"
                            onClick={addItem}
                        >
                            {t("addNewItem")}
                        </Button>
                    )
                }

            </div>



        </div>
    )
}

export default WebFoundamentalItem