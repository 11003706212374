import React, { useCallback, useContext, useMemo, useState } from "react";
import { Accordion, AccordionItem, Input, Button, ButtonGroup, ListboxItem, Listbox } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, useDisclosure } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'

import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"
import { illustrations } from "cvpop-assets-sdk";
import { colors } from "cvpop-constants-sdk";
import { faicons } from "cvpop-collections-sdk";

import _ from "lodash"

import { createFbId } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";

import { AlertContext } from "../../../../contexts/AlertContext";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";


const WebHobby = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const [itemSelectedKeys, setItemSelectedKeys] = useState([]);

    // ------------------------------------------------------------------------------------------------------
    const list = useMemo(() => cv.SkillsPassport.LearnerInfo.Hobby || [], [cv]);
    const iconList = useMemo(() => _.groupBy(_.sortBy(faicons, e => e.category), e => t(`iconCat${_.upperFirst(e.category)}`)), [])

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {
        const Id = createFbId()
        editCv(produce(cv, d => {
            d.SkillsPassport.LearnerInfo.Hobby?.length >= 0 ?
                d.SkillsPassport.LearnerInfo.Hobby.push({ Id, Title: "", Icon: "heart" }) :
                d.SkillsPassport.LearnerInfo.Hobby = [{ Id, Title: "", Icon: "heart" }]
        }))
        setItemSelectedKeys([Id])
    }, [cv])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.Hobby.filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.Hobby = newList
            })
        )
    }, [cv])

    const moveItem = useCallback((a, b) => {
        const copy = (cv.SkillsPassport.LearnerInfo.Hobby).slice();
        [copy[a], copy[b]] = [copy[b], copy[a]];
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Hobby = copy }) }))
    }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelHobby")} />
            <div className="p-5">

                {
                    list.length === 0 && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.ability }}
                                title={t(`cvLabelHobby`)}
                                message={t("emptyListMsg")}
                            >
                                <Button fullWidth color={"primary"} className="mt-6" onClick={addItem}>{t("add")}</Button>
                                <WebAIAssistantButton color={"secondary"} sectionId={"HOBBY"} /> 
                            </WebCenterView>
                        </div>
                    )
                }



                <Accordion variant="splitted" selectedKeys={itemSelectedKeys} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {
                        list.map(({ Id, Title, Icon }, i) => (
                            <AccordionItem
                                isCompact
                                startContent={list.length > 1 && (
                                    <ButtonGroup variant={"light"}>
                                        <Button isDisabled={i === 0} isIconOnly size={"sm"} onClick={() => moveItem(i, i - 1)}>
                                            <WebIcon name={"arrow_upward"} className={"text-base"} />
                                        </Button>
                                        <Button isDisabled={i == list.length - 1} isIconOnly size={"sm"} onClick={() => moveItem(i, i + 1)}>
                                            <WebIcon name={"arrow_downward"} className={"text-base"} />
                                        </Button>
                                    </ButtonGroup>
                                )}
                                onPress={() => setItemSelectedKeys((itemSelectedKeys[0] || "") === Id ? [] : [Id])}
                                title={Title || t("elementWithNoTitle")}
                                key={Id}
                            >

                                <Input
                                    label={t("cvLabelHobby")}
                                    placeholder={t("hobbyTitlePh")}
                                    value={Title}
                                    onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Hobby[${i}].Title`, target.value)))}
                                />

                                <Listbox>
                                    <ListboxItem
                                        key="icon"
                                        title={t('icon')}
                                        className="mt-3"
                                        onPress={onOpen}
                                        endContent={(
                                            <div className="flex items-center">
                                                <svg
                                                    fill={colors[cs].text}
                                                    style={{ width: 20, height: 20 }}
                                                    className="mr-1"
                                                    viewBox={`${faicons.find(e => e.code === Icon).svg.viewBox.join(' ')}`}
                                                >
                                                    <path d={`${faicons.find(e => e.code === Icon).svg.path}`} />
                                                </svg>
                                                <WebIcon name={"chevron_right"} />
                                            </div>
                                        )}
                                    />
                                </Listbox>

                                <Button
                                    fullWidth
                                    color={"danger"}
                                    className="my-2"
                                    onPress={() => confirmDelete().then(() => deleteItem(Id)).catch(() => { })}
                                >
                                    {t("delete")}
                                </Button>

                            </AccordionItem>
                        ))
                    }
                </Accordion>

                {list.length > 0 && (
                    <>
                        <Button
                            fullWidth
                            color={"primary"}
                            variant={"ghost"}
                            className="mt-4"
                            onClick={addItem}
                        >
                            {t("addNewItem")}
                        </Button>
                        <WebAIAssistantButton sectionId={"HOBBY"} />
                    </>
                )}

            </div>

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t("icons")}</ModalHeader>
                            <ModalBody className="mx-4 rounded">

                                {
                                    Object.keys(iconList).map((c, i) => (
                                        <div key={`iconCat${i}`}>
                                            <WebListItemHeaderView text={c} style={{ margin: 0 }} />
                                            {
                                                iconList[c].map((icon, k) => (
                                                    <Button
                                                        isIconOnly
                                                        className="mr-2 mt-2"
                                                        key={`icon${k}`}
                                                        onPress={() => {
                                                            const i = list.findIndex(e => e.Id === itemSelectedKeys[0])
                                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Hobby[${i}].Icon`, icon.code)))
                                                            onClose()
                                                        }}
                                                    >
                                                        <svg fill={colors[cs].text} viewBox={`${icon.svg.viewBox.join(' ')}`} style={{ width: 20, height: 20 }}>
                                                            <path d={`${icon.svg.path}`} />
                                                        </svg>
                                                    </Button>
                                                ))
                                            }
                                        </div>
                                    ))
                                }


                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </div>
    )
}

export default WebHobby