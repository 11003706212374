import { useCallback, useContext, useState } from "react"
import { Card, CardBody, Input, Listbox, ListboxItem, Select, SelectItem, Switch, Button, Spinner } from "@nextui-org/react"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, Outlet } from "react-router";
import { getBoolean } from "@firebase/remote-config";
import { doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import { useMemo } from "react";
import { produce } from "immer"

import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"
import { cvPickerItems } from "cvpop-constants-sdk";

import { auth, db, functions, remoteConfig, storage } from "../../../../controllers/firebase";
import { errorHandler, goToPaywall } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";



const WebCVSettings = () => {

    // ------------------------------------------------------------------------------------------------------
    const { userPro } = useSelector(state => state.userReducer)
    const { cv } = useSelector(state => state.cvReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()
    const { alert, confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const fbuser = auth.currentUser;
    const n = useNavigate()
    const d = useDispatch();
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)
    const [loadingPublicLink, setLoadingPublicLink] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const met = useMemo(() => cv.Metadata, [cv])
    const languageItems = useMemo(() => cvPickerItems.labelLanguage(), [])
    const sizeItems = useMemo(() => cvPickerItems.pdfSize(), [])
    const lawItems = useMemo(() => cvPickerItems.privacyLaw(), [])

    const api = useMemo(() => httpsCallable(functions, 'getCVPublicLinkId'), []);

    const sizeOn = useMemo(() => (getBoolean(remoteConfig, "compressCv")), [])

    // ------------------------------------------------------------------------------------------------------
    const runDeleteCv = useCallback(async () => {

        const { id, uid } = cv.Metadata

        setLoading(true)
        deleteDoc(doc(db, "00_RESUMES_00", id))
            .then(async () => {

                try {
                    const hasVideo = cv.SkillsPassport.LearnerInfo.Video;
                    if (hasVideo) await deleteObject(ref(storage, `videoResume/${uid}-${id}.mp4`))

                    const attachments = cv.SkillsPassport.LearnerInfo.Attachment || []
                    for (let f in attachments) await deleteObject(ref(storage, `attachments/${uid}/${id}/${attachments[f].filename}`))
                } catch (e) {
                    errorHandler(alert, "err_delete_cv_accessories", e, true)
                }

                n(`/app/${platform}/home/cv-list`)

            })
            .catch(e => errorHandler(alert, "err_delete_cv", e))
            .finally(() => setLoading(false))

    }, [cv])


    // ------------------------------------------------------------------------------------------------------------------------
    if (![`/app/${platform}/cv/settings/`, `/app/${platform}/cv/settings`].includes(pathname)) return <Outlet />
    return (
        <div>
            <WebHeader title={t("cvSettings")} />
            <div className="mx-5 mb-5">

                <WebListItemHeaderView text={t('generals')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">

                        <Input
                            startContent={<span className="material-symbols-outlined">title</span>}
                            placeholder={t("name")}
                            value={met.name}
                            onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.name = target.value ? target.value : `${t("cv")} ${fbuser.displayName}`.trim() }) }))}
                        />
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/settings/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="date-format"
                                startContent={<span className="material-symbols-outlined">edit_calendar</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                {t('dateFormat')}
                            </ListboxItem>
                            <ListboxItem
                                key="sort-sections"
                                startContent={<span className="material-symbols-outlined">format_line_spacing</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                {t('sectionSort')}
                            </ListboxItem>
                        </Listbox>

                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('sectionTitles')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">

                        <Select
                            label={t("language")}
                            defaultSelectedKeys={[met.lng]}
                            disallowEmptySelection={true}
                            classNames={{ popoverContent: `${cs} text-foreground` }}
                            onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.lng = target.value }) }))}
                        >
                            {languageItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{label}</SelectItem>))}
                        </Select>
                        <Listbox
                            aria-label="Actions"
                            onAction={k => n(`/app/${platform}/cv/settings/${k}`)}
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="custom-titles"
                                startContent={<span className="material-symbols-outlined">abc</span>}
                                endContent={<span className="material-symbols-outlined">chevron_right</span>}
                            >
                                {t('customizeLabels')}
                            </ListboxItem>
                        </Listbox>

                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('export')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">

                        {sizeOn && userPro && (
                            <Select
                                label={t("featCompression")}
                                defaultSelectedKeys={[(met.pdfSize || "STANDARD")]}
                                disallowEmptySelection={true}
                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                startContent={<span className="material-symbols-outlined">folder_zip</span>}
                                onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.pdfSize = target.value }) }))}
                                style={{ backgroundColor: "transparent", boxShadow: "none" }}
                            >
                                {sizeItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{t(label)}</SelectItem>))}
                            </Select>
                        )}

                        <Select
                            label={t("privacyCvLaw")}
                            defaultSelectedKeys={[(met.privacyLaw || "")]}
                            disallowEmptySelection={true}
                            classNames={{ popoverContent: `${cs} text-foreground` }}
                            startContent={<span className="material-symbols-outlined">folder_zip</span>}
                            onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.privacyLaw = target.value }) }))}
                            style={{ backgroundColor: "transparent", boxShadow: "none" }}
                        >
                            {lawItems.map(({ code, label }) => (<SelectItem key={code} value={code}>{t(label)}</SelectItem>))}
                        </Select>

                        <Listbox
                            aria-label="Actions"
                            variant={"flat"}
                        >

                            {sizeOn && !userPro && (
                                <ListboxItem
                                    key="compression"
                                    onClick={() => goToPaywall(offering, platform, n)}
                                    startContent={<span className="material-symbols-outlined">folder_zip</span>}
                                    endContent={<span className="material-symbols-outlined filled text-yellow-400">workspace_premium</span>}
                                >
                                    {t('featCompression')}
                                </ListboxItem>
                            )}

                            {
                                !userPro && (
                                    <ListboxItem
                                        key="watermark"
                                        onClick={() => goToPaywall(offering, platform, n)}
                                        startContent={<span className="material-symbols-outlined">branding_watermark</span>}
                                        endContent={<span className="material-symbols-outlined filled text-yellow-400">workspace_premium</span>}
                                    >
                                        {t('featWatermaker')}
                                    </ListboxItem>
                                )
                            }


                            <ListboxItem
                                key="hide-cv-date"
                                startContent={<span className="material-symbols-outlined">event_busy</span>}
                                endContent={(
                                    <Switch
                                        aria-label="Automatic updates"
                                        value={(met.hideCvDate)}
                                        onValueChange={v => d(setCv({ cv: produce(cv, d => { d.Metadata.hideCvDate = v }) }))}
                                    />
                                )}
                            >
                                {t('hideCvDate')}
                            </ListboxItem>

                            <ListboxItem
                                key="hide-pagination"
                                startContent={<span className="material-symbols-outlined">123</span>}
                                endContent={(
                                    <Switch
                                        aria-label="Automatic updates"
                                        value={(met.hideCvPagination)}
                                        onValueChange={v => d(setCv({ cv: produce(cv, d => { d.Metadata.hideCvPagination = v }) }))}
                                    />
                                )}
                            >
                                {t('hideCvPagination')}
                            </ListboxItem>

                            <ListboxItem
                                key="public-link"
                                startContent={<span className="material-symbols-outlined">public</span>}
                                description={met.publicLinkId && `cvpop.com/cv/${met.publicLinkId}`}
                                endContent={
                                    loadingPublicLink ? (
                                        <Spinner size={"sm"} className={"mr-3"} />
                                    ) : (
                                        <Switch
                                            aria-label="Automatic updates"
                                            isSelected={(!!met.publicLinkId)}
                                            onValueChange={v => {
                                                if (!v) return d(setCv({ cv: produce(cv, d => { delete d.Metadata.publicLinkId }) }))
                                                setLoadingPublicLink(true)
                                                api({ cvId: met.id })
                                                    .then(({ data }) => d(setCv({ cv: produce(cv, d => { d.Metadata.publicLinkId = data.publicLinkId }) })))
                                                    .catch(e => errorHandler("err_get_cv_public_id", e))
                                                    .finally(() => setLoadingPublicLink(false))
                                            }}
                                        />
                                    )
                                }
                            >
                                {t('onlineAvailable')}
                            </ListboxItem>
                        </Listbox>

                    </CardBody>
                </Card>

                <Button
                    fullWidth
                    color={"danger"}
                    className='mt-4'
                    isLoading={loading}
                    onPress={() => confirmDelete().then(runDeleteCv).catch(() => { })}
                >
                    {t('delete')}
                </Button>
            </div>
        </div >
    )
}

export default WebCVSettings