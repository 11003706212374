import { useCallback, useContext, useMemo, useState } from "react"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import { Avatar, Listbox, ListboxItem, Button, Card, CardBody, Input } from "@nextui-org/react";
import { httpsCallable } from "firebase/functions";

import { setJobList } from "cvpop-redux-sdk";
import { icons } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { functions } from "../../../../controllers/firebase";
import { errorHandler, getCountryCodeFromCity } from "../../../../controllers/app";
import { AlertContext } from "../../../../contexts/AlertContext";


const WebJobContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { jobList } = useSelector(state => state.jobReducer)
    const { platform, jobId } = useParams()
    const { pathname } = useLocation()

    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const { professionalTitle, language } = user || {}
    const { phcompanylogo } = icons

    // ------------------------------------------------------------------------------------------------------------------------
    const countryCode = useMemo(() => getCountryCodeFromCity(), []);
    const isWeb = useMemo(() => platform === "web", [platform])

    const jobApi = useMemo(() => httpsCallable(functions, "getJobs"), []);

    // ------------------------------------------------------------------------------------------------------------------------
    const [query, setQuery] = useState(professionalTitle || "")
    const [location, setLocation] = useState(user.location || "")

    const [nextPage, setNextPage] = useState(null)

    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    // ------------------------------------------------------------------------------------------------------
    const searchJobs = useCallback((query, location, nextPage) => {
        nextPage ? setLoadingMore(true) : setLoadingSearch(true)
        jobApi({ query, location, nextPage, language, countryCode })
            .then(({ data }) => {
                if (data.hasError) return errorHandler(alert, "err_search_jobs", { code: data.errors?.[0] || "", message: data.errors?.[0] || "" })
                if (data.jobs.length === 0 && !nextPage) return alert(t("noJobResults"), t("noJobResultsMsg"))
                setNextPage(data.nextPage)
                d(setJobList([...jobList, ...data.jobs]))
            })
            .catch(e => errorHandler(alert, "err_search_jobs", e))
            .finally(() => nextPage ? setLoadingMore(false) : setLoadingSearch(false))
    }, [language, countryCode, jobList, jobApi])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        d(setJobList([]))
        setNextPage(null)

        setLoadingSearch(false)
        setLoadingMore(false)
    }, [query, location])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="flex" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

            <div className="w-4/12  overflow-y-auto p-1 pb-8 border-r border-divider" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

                <div className="p-2">
                    <Card shadow={"none"}>
                        <CardBody>
                            <Input
                                label={t("jobTitle")}
                                placeholder={t("jobTitlePh")}
                                value={query}
                                onChange={({ target }) => setQuery(target.value)}
                            />
                            <Input
                                label={t("location")}
                                placeholder={t("cityStatePh")}
                                value={location}
                                onChange={({ target }) => setLocation(target.value)}
                                className="mt-2"
                            />
                        </CardBody>
                    </Card>

                    {
                        jobList.length === 0 && (
                            <Button
                                color={"primary"}
                                className="mt-4 w-full"
                                isDisabled={!query && !location}
                                isLoading={loadingSearch}
                                onClick={() => searchJobs(query, location, nextPage)}
                            >
                                {t("search")}
                            </Button>
                        )
                    }
                </div>


                {
                    jobList.length > 0 && (
                        <div>
                            <Card shadow={"none"} className="mx-2">
                                <Listbox
                                    variant={"flat"}
                                    onAction={k => n(k)}
                                    disabledKeys={[jobId]}
                                    classNames={{ base: "pb-0" }}
                                >
                                    {
                                        jobList.map(({ title, company, image, id }, i) => (
                                            <ListboxItem
                                                key={id}
                                                startContent={(
                                                    <Avatar
                                                        radius="sm"
                                                        size="lg"
                                                        className="bg-transparent"
                                                        imgProps={{ className: "object-contain p-1" }} src={image || phcompanylogo} name={company}
                                                    />
                                                )}
                                                title={title}
                                                description={company}
                                                className={jobId === id && `opacity-100 text-primary-foreground bg-primary`}
                                                classNames={{ title: "whitespace-normal font-semibold", description: jobId === id && "text-primary-foreground" }}
                                                showDivider={i < jobList.length - 1}
                                            />
                                        ))
                                    }
                                </Listbox>
                            </Card>
                            {_.some(_.valuesIn(nextPage), p => p !== "N/A") && (
                                <div className="px-2">
                                    <Button
                                        color={"primary"}
                                        className="mt-2 w-full"
                                        isLoading={loadingMore}
                                        onClick={() => searchJobs(query, location, nextPage)}
                                    >
                                        {t("showMore")}
                                    </Button>
                                </div>

                            )}
                        </div>
                    )
                }

            </div>

            {
                (([`/app/${platform}/home/job-list`, `/app/${platform}/home/job-list/`].includes(pathname)) || jobList.length === 0) ? (
                    <div className="w-8/12 bg-content1 flex items-center justify-center" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
                        <p className="text-3xl text-center text-default p-8">{t("noItemsSelected")}</p>
                    </div>
                ) : (
                    <Outlet />
                )
            }
        </div>
    )
}

export default WebJobContainer