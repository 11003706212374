import { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { produce } from "immer"
import { useNavigate, useParams } from "react-router-dom";

import { models } from "cvpop-assets-sdk"
import { setCv } from "cvpop-redux-sdk"
import { modelCatalogue } from 'cvpop-constants-sdk';
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { goToPaywall } from '../../../controllers/app';
import { AlertContext } from '../../../contexts/AlertContext';


const WebModelPreviewCard = ({ item, index }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { confirm } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const { code } = item
    const { modelType, modelArrange, lng } = cv.Metadata

    // ------------------------------------------------------------------------------------------------------------------------
    const cvModel = useMemo(() => _.find(modelCatalogue, o => o.code === (modelType || 'london')), [modelType])
    const sel = useMemo(() => code === (modelType || "london"), [modelType])

    // ------------------------------------------------------------------------------------------------------
    const onPress = useCallback(async () => {

        let delArrange = false;
        if (modelArrange && cvModel.layout !== item.layout) {
            try {
                await confirm(t('layoutChange'), t('layoutChangeMsg'), t('continue'));
                delArrange = true
            } catch (e) { return }
        }
        d(setCv({ cv: produce(cv, d => { d.Metadata = { ...d.Metadata, modelType: code, modelArrange: delArrange ? null : (d.Metadata.modelArrange || null) } }) }))

    }, [cv])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <a
            key={item.code}
            className={`relative border-4 border-${sel ? "primary" : "default"} rounded-3xl p-1`}
            onClick={sel ? null : () => (userPro || item.isFree) ? onPress() : goToPaywall(offering, platform, n)}
        >
            <img src={models[item.code]} className="object-cover rounded-2xl relative" style={{ transform: `scaleX(${lng === "ar" ? -1 : 1})` }} />

            {(!sel && !userPro && !item.isFree) && (
                <div className='absolute bg-yellow-500 w-8 h-8 flex justify-center items-center shadow-2xl bottom-2 right-2 rounded-full'>
                    <span className="material-symbols-outlined filled text-white">workspace_premium</span>
                </div>
            )}
        </a>
    )
}

export default WebModelPreviewCard