import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setUser, setCv } from 'cvpop-redux-sdk';
import { produce } from 'immer';
import { doc, updateDoc } from "firebase/firestore";

import _ from "lodash";

import { db, auth } from './firebase';
import { errorHandler } from './app';


const userMiddleware = createListenerMiddleware()
const cvMiddleware = createListenerMiddleware()

const syncCv = (cvToSave, previousCv) => {
    const cv = produce(cvToSave, d => {
        if (!_.isEqual(cvToSave.SkillsPassport, previousCv?.SkillsPassport)) d.Metadata.updatedContentAt = new Date().getTime()
        d.Metadata.updatedAt = new Date().getTime()
        d.SkillsPassport.LearnerInfo.Ability = (cvToSave.SkillsPassport.LearnerInfo.Ability || []).map(a => ({ ...a, Level: (_.isNaN(a.Level) || isNaN(a.Level)) ? 5 : a.Level }))
    })
    updateDoc(doc(db, "00_RESUMES_00", cv.Metadata.id), cv).catch(e => errorHandler(null, "err_update_cv", e))
}

const debouncedSyncCv = _.debounce(syncCv, 1000);


userMiddleware.startListening({
    actionCreator: setUser,
    effect: (action, listenerApi) => {
        const { ignoreListener, user } = action.payload
        const { uid } = auth.currentUser || {};

        if (ignoreListener || !user || !uid) return;
        updateDoc(doc(db, "00_USERS_00", uid), user).catch(e => errorHandler(null, "err_update_user", e))
    },
})


cvMiddleware.startListening({
    actionCreator: setCv,
    effect: (action, listenerApi) => {
        const { ignoreListener, cv } = action.payload;

        if (ignoreListener || _.isEmpty(cv) || !cv?.Metadata?.id) return;
        const previousCv = listenerApi.getOriginalState()?.cvReducer?.cv
        debouncedSyncCv(cv, previousCv)
    },
})

export const getUserMiddleware = () => userMiddleware;
export const getCvMiddleware = () => cvMiddleware;