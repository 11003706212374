import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Listbox, ListboxItem, Button, CardHeader, Divider, Spinner } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { illustrations } from "cvpop-assets-sdk";
import { getEnglishLabelFromLanguageCode } from "cvpop-utility-sdk";
import { setSpin } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { db, functions } from "../../../../controllers/firebase";
import { errorHandler, goToPaywall } from "../../../../controllers/app";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebIcon from "../../../atoms/WebIcon"
import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";
import WebErrorView from "../../../molecules/views/WebErrorView";


const WebCVAnalysis = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const ref = useRef()

    // ------------------------------------------------------------------------------------------------------
    const { id, updatedContentAt = 0 } = cv?.Metadata || {};

    // ------------------------------------------------------------------------------------------------------
    const [analysis, setAnalysis] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getCVAIAnalysis', { timeout: 120000 }), []);

    const documentMap = useMemo(() => [
        { key: "content", icon: "description" },
        { key: "style", icon: "palette" },
        { key: "grammar", icon: "spellcheck" },
        { key: "length", icon: "subject" },
    ], [])

    const sectionMap = useMemo(() => [
        { key: "personalInfo", label: t("personalData"), icon: "id_card", navKey: "personal-data" },
        { key: "contacts", label: t("contacts"), icon: "call", navKey: "" },
        { key: "summary", label: t("cvLabelSummary"), icon: "grading", navKey: "summary" },
        { key: "workExperience", label: t("cvLabelExperience"), icon: "business_center", navKey: "work-experience" },
        { key: "education", label: t("cvLabelEducation"), icon: "school", navKey: "education" },
        { key: "skills", label: t("cvLabelSkills"), icon: "psychology", navKey: "" },
        { key: "keywords", label: t("cvLabelKeyword"), icon: "label", navKey: "keywords" },
        { key: "abilities", label: t("cvLabelAbility"), icon: "align_horizontal_left", navKey: "abilities" },
        { key: "hobbies", label: t("cvLabelHobby"), icon: "favorite", navKey: "hobbies" },
        { key: "achievements", label: t("cvLabelAchievement"), icon: "post_add", navKey: "achievements", },
    ], [])

    // ------------------------------------------------------------------------------------------------------
    const getIconByRate = useCallback(r => {
        if (r < 20) return "clock_loader_20"
        if (r >= 20 && r < 40) return "clock_loader_40"
        if (r >= 40 && r < 60) return "clock_loader_60"
        if (r >= 60 && r < 80) return "clock_loader_80"
        if (r >= 80) return "clock_loader_100"
    }, [cv])

    const getColorByRate = useCallback(r => {
        if (r < 30) return "text-danger"
        if (r >= 30 && r < 70) return "text-warning"
        if (r >= 70) return "text-success"
    }, [])

    const getAnalysis = useCallback(() => {

        setLoading(true)
        setError(false)
        getDoc(doc(db, "00_RESUMES_ANALYSIS_00", id))
            .then(doc => doc.exists() && setAnalysis(doc.data()))
            .catch(e => errorHandler(alert, "err_get_cv_analysis", e, true, () => setError(true)))
            .finally(() => setLoading(false))

    }, [])

    const createAnalysis = useCallback(async () => {

        d(setSpin(true))
        api({ cvId: id, hLng: getEnglishLabelFromLanguageCode(user.language) })
            .then(({ data }) => {
                setAnalysis(data)
                ref?.current?.scrollTo(0, 0);
            })
            .catch(e => errorHandler(alert, "err_request_analysis", e))
            .finally(() => d(setSpin(false)))

    }, [user]);

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { getAnalysis() }, [])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvAnalysis")} />
            <div className="p-5">

                {
                    loading ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 150px)` }}>
                            <Spinner size="lg" />
                        </div>
                    ) : error ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 150px)` }}>
                            <WebErrorView buttonProps={{ onPress: getAnalysis }} />
                        </div>
                    ) : !analysis ? (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.analysis }}
                                title={t("cvAnalysis")}
                                message={t("cvAnalysisMsg")}
                            >
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    onPress={() => !userPro ? goToPaywall(offering, platform, n) : createAnalysis()}
                                    endContent={!userPro && (
                                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                            <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                                        </div>
                                    )}
                                >
                                    {t(!userPro ? "unlock" : "showAnalysis")}
                                </Button>
                            </WebCenterView>
                        </div>
                    ) : (
                        <div ref={ref}>

                            <Card shadow={"none"}>
                                <CardHeader className="flex gap-3 justify-between">
                                    <div className="flex">
                                        <p className="text-xl font-bold flex w-full">{t('overallRating')}</p>
                                    </div>
                                    <div className="flex items-center">
                                        <p className={`text-xl font-bold ${getColorByRate(analysis.overall.score)}`}>{`${analysis.overall.score}/100`}</p>
                                        <WebIcon className={`ml-2 ${getColorByRate(analysis.overall.score)}`} name={getIconByRate(analysis.overall.score)} />
                                    </div>
                                </CardHeader>
                                <Divider />
                                <CardBody>
                                    <p>{analysis.overall.description}</p>
                                </CardBody>
                            </Card>

                            <WebListItemHeaderView text={`${t('document')}`} />
                            <Card shadow={"none"}>
                                {
                                    documentMap.map(({ key, icon }) => (
                                        <>
                                            <CardHeader className="flex gap-3 justify-between">
                                                <div className="flex">
                                                    <WebIcon name={icon} />
                                                    <p className="text-md ml-2 font-bold flex w-full">{t(key)}</p>
                                                </div>
                                                <div className="flex">
                                                    <p className={`text-md font-bold ${getColorByRate(analysis[key]?.score)}`}>{`${analysis[key]?.score}/100`}</p>
                                                </div>
                                            </CardHeader>
                                            <Divider />
                                            <CardBody>
                                                <p className="text-sm text-default-500">{analysis[key]?.description}</p>
                                            </CardBody>
                                        </>
                                    ))
                                }
                            </Card>

                            <WebListItemHeaderView text={t('sections')} />

                            <Card shadow={"none"}>
                                <Listbox aria-label="Actions" variant={"flat"} disabledKeys={sectionMap.filter(({ key }) => analysis.sectionAdvices[key]?.type === "goodassessment").map(e => e.key)}>
                                    {
                                        sectionMap.map(({ key, label, icon, navKey, navOpt }, i) => (
                                            <ListboxItem
                                                key={key}
                                                title={label}
                                                classNames={{ base: "opacity-100" }}
                                                description={analysis.sectionAdvices[key]?.feedback}
                                                startContent={<WebIcon name={icon} />}
                                                onClick={analysis.sectionAdvices[key]?.type === "goodassessment" ? null : () => n(`/app/${platform}/cv/edit/${navKey}`)}
                                                endContent={analysis.sectionAdvices[key]?.type !== "goodassessment" && (
                                                    <div className="flex items-center">
                                                        <WebIcon name={"chevron_right"} />
                                                    </div>
                                                )}
                                                showDivider={!!sectionMap[i + 1]}
                                            />
                                        ))
                                    }
                                </Listbox>
                            </Card>

                            {
                                (updatedContentAt < analysis.createdAt) ? (
                                    <p className="mt-4 text-xs text-default-600">{t("updateCvToRetryAnalysisTip")}</p>
                                ) : (
                                    <Button
                                        fullWidth
                                        color="primary"
                                        className="mt-4"
                                        endContent={!userPro && (
                                            <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                                                <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                                            </div>
                                        )}
                                        onPress={() => createAnalysis()}
                                    >
                                        {t("tryAgain")}
                                    </Button>
                                )
                            }

                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default WebCVAnalysis