import { useMemo, useRef, useState, useCallback, useContext } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { produce } from "immer"
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { setCv } from "cvpop-redux-sdk";
import { illustrations } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk"

import { errorHandler, openUrl } from "../../../../controllers/app";
import { auth, functions, storage } from "../../../../controllers/firebase";
import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebVideo = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()
    const { alert, confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const { id } = cv.Metadata || {}
    const { uid } = fbuser || {}
    const { url, qrCodeData } = cv.SkillsPassport.LearnerInfo.Video || {}

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'createqrcode'), []);

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const deleteVideo = useCallback(() => {

        setLoading(true)
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Video = null }) }))
        deleteObject(ref(storage, `videoResume/${fbuser.uid}-${cv.Metadata.id}.mp4`))
            .catch(e => errorHandler(alert, "err_delete_video", e, true))
            .finally(() => setLoading(false))

    }, [fbuser, cv]);


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>

            <WebHeader title={t("cvLabelVideo")} />
            <div className="p-5">
                <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                    <WebCenterView
                        imageProps={{
                            src: qrCodeData ? `data:image/png;base64,${qrCodeData}` : illustrations.video,
                            className: `${(qrCodeData) ? "bg-white" : ""} rounded`
                        }}
                        title={t("featVideo")}
                        message={t(`videoPresentationMsg`)}
                    >

                        {
                            !qrCodeData ? (
                                <Button
                                    color="primary"
                                    className="mt-6"
                                    isLoading={loading}
                                    onPress={() => {
                                        if (!inputRef.current) return
                                        inputRef.current.value = ""
                                        inputRef.current.click()
                                    }}
                                >
                                    {t("add")}
                                </Button>
                            ) : (
                                <Dropdown backdrop="blur" className={`${cs} text-foreground`} isDisabled={loading}>
                                    <DropdownTrigger>
                                        <Button color="primary" className="mt-6" isLoading={loading}>
                                            {t("showOptions")}
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        aria-label="Static Actions"
                                        color={"primary"}
                                        onAction={k => {
                                            if (k === "delete") return confirmDelete().then(deleteVideo).catch(() => { })
                                            if (k === "open") return openUrl(url, platform)
                                        }}
                                    >
                                        <DropdownItem key="open" title={t("open")} endContent={<WebIcon name={"open_in_new"} />} />
                                        <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                                    </DropdownMenu>
                                </Dropdown>
                            )
                        }

                        <WebAIAssistantButton color={"secondary"} sectionId={"VIDEO"} />
                    </WebCenterView>

                    <input
                        type="file"
                        className='hidden'
                        accept="video/mp4"
                        ref={inputRef}
                        onChange={async ({ target }) => {
                            if (target.files.length === 0) return
                            if (!["video/mp4"].includes(target.files[0].type)) return alert(t("errTitle"), t("notSupportedTypeFile"))

                            const video = target.files[0]
                            let stRef = ref(storage, `videoResume/${uid}-${id}.mp4`);

                            try {
                                setLoading(true)
                                const snap = await uploadBytes(stRef, video)
                                const url = await getDownloadURL(snap.ref)
                                const qrCodeData = await api({ url })
                                d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Video = { url, qrCodeData: qrCodeData.data, createdAt: new Date().getTime() } }) }));
                                setLoading(false)
                            } catch (e) {
                                setLoading(false)
                                deleteObject(stRef)
                                errorHandler(alert, "err_get_video", e)
                            }

                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default WebVideo