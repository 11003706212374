import { useNavigate, useParams } from "react-router-dom";
import { Navbar, NavbarBrand, Card, CardBody } from "@nextui-org/react";

import { t } from 'cvpop-localization-sdk';
import { logos, illustrations } from 'cvpop-assets-sdk';

import _ from "lodash"

import { useColorScheme } from '../../../controllers/hooks';


const WebAdminUserFeatureRequest = ({ }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { result } = useParams() // not-found, expired, success, error

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div>
            <Navbar maxWidth={"full"} className='bg-content1' isBordered>
                <NavbarBrand>
                    <img
                        alt={"cvpop"}
                        src={logos.apptext[cs]}
                        className='w-28 cursor-pointer'
                        onClick={() => n(`/`)}
                    />
                </NavbarBrand>
            </Navbar>
            <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh -  4rem)` }}>
                <Card className='max-w-md m-3 mb-0'>
                    <CardBody>
                        <div className="max-w-80 self-center">
                            <img alt={t("introAvatarTitle")} src={illustrations[result.replace("-", "")]} className={`rounded-xl`} />
                        </div>
                        <p className="font-sans font-bold text-2xl text-center mt-3">{t(result === "error" ? `genericError` : "recoverCvRequestSuccessTitle")}</p>
                    </CardBody>
                </Card>
            </div>
        </div>
    )

}

export default WebAdminUserFeatureRequest