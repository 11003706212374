
import user from "./src/userReducer";
import cv from "./src/cvReducer";
import job from "./src/jobReducer";
import news from "./src/newsReducer";
import course from "./src/courseReducer";
import book from "./src/bookReducer";
import web from "./src/webReducer";
import app from "./src/appReducer"

export const userReducer = user.reducer
export const setUser = user.actions.setUser
export const setUserPro = user.actions.setUserPro
export const setUserAms = user.actions.setUserAms
export const setUserFreeAds = user.actions.setUserFreeAds
export const setUserCanBePro = user.actions.setUserCanBePro
export const setUserStandardTrialEligible = user.actions.setUserStandardTrialEligible
export const setUserOfferTrialEligible = user.actions.setUserOfferTrialEligible
export const setAdUserChoices = user.actions.setAdUserChoices
export const setUserLogged = user.actions.setUserLogged

export const cvReducer = cv.reducer
export const setCvList = cv.actions.setCvList
export const setCv = cv.actions.setCv
export const setCvLocalList = cv.actions.setCvLocalList
export const setCvItmLogoWebImg = cv.actions.setCvItmLogoWebImg
export const setCvBgWebImg = cv.actions.setCvBgWebImg

export const jobReducer = job.reducer
export const setJobList = job.actions.setJobList

export const newsReducer = news.reducer
export const setNewsList = news.actions.setNewsList

export const courseReducer = course.reducer
export const setCourseList = course.actions.setCourseList

export const bookReducer = book.reducer
export const setBookList = book.actions.setBookList

export const appReducer = app.reducer
export const setSpin = app.actions.setSpin
export const setVersion = app.actions.setVersion
export const setOffering = app.actions.setOffering
export const setOfferingProductList = app.actions.setOfferingProductList
export const setIapOn = app.actions.setIapOn
export const setIapProductList = app.actions.setIapProductList
export const setCvNewFlowEnabled = app.actions.setCvNewFlowEnabled
export const setCvPreviewEnabled = app.actions.setCvPreviewEnabled
export const setReviewModeEnabled = app.actions.setReviewModeEnabled
export const setBetaTesterModeEnabled = app.actions.setBetaTesterModeEnabled
export const setCvStyleBSIndex = app.actions.setCvStyleBSIndex
export const setCvOptionsBSIndex = app.actions.setCvOptionsBSIndex
export const setCvTextMoreBSIndex = app.actions.setCvTextMoreBSIndex

export const webReducer = web.reducer