import { useCallback, useMemo } from "react";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "cvpop-localization-sdk";

import _ from "lodash"

const WebCVBreadcrumb = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const crumbList = useMemo(() => _.drop(_.compact(pathname.split("/")), 2).filter(e => e !== "app" && e !== platform), [pathname])

    // ------------------------------------------------------------------------------------------------------------------------
    const getCrumName = useCallback(k => {
        if (k === "cv") return cv.Metadata.name
        if (k === "personal-data") return t('personalData')
        if (k === "contact-info-phones") return t('phones')
        if (k === "contact-info-websites") return t('websites')
        if (k === "contact-info-social-accounts") return t('socialAccount')
        if (k === "contact-info-instant-messaging") return t('instMessages')
        if (k === "summary") return t('cvLabelSummary')
        if (k === "work-experience") return t('cvLabelExperience')
        if (k === "education") return t('cvLabelEducation')
        if (k === "communication-skills") return t('cvLabelSkillsCommunication')
        if (k === "organisational-skills") return t('cvLabelSkillsOrganisational')
        if (k === "job-related-skills") return t('cvLabelSkillsJobRelated')
        if (k === "computer-skills") return t('cvLabelSkillsComputer')
        if (k === "other-skills") return t('cvLabelSkillsOther')
        if (k === "driver-licence") return t('cvLabelDriverLicence')
        if (k === "special-driver-licence") return t('cvLabelSpecialDriverLicence')
        if (k === "mother-tongue") return t('cvLabelMotherTongue')
        if (k === "foreign-languages") return t('cvLabelForeignLanguage')
        if (k === "headline") return t('cvLabelHeadline')
        if (k === "keywords") return t('cvLabelKeyword')
        if (k === "abilities") return t('cvLabelAbility')
        if (k === "achievements") return t('cvLabelAchievement')
        if (k === "portfolio") return t('cvLabelPortfolio')
        if (k === "attachments") return t('cvLabelAttachment')
        if (k === "video-presentation") return t('cvLabelVideo')
        if (k === "cover-letter") return t('coverLetter')
        if (k === "header-image") return t('bgImg')
        if (k === "header-texture") return t('texture')
        if (k === "photo-format") return t('photoFormat')
        if (k === "date-format") return t('dateFormat')
        if (k === "sort-sections") return t('sectionSort')
        if (k === "custom-titles") return t('customizeLabels')
        if (k === "questions") return t('questionsAndAnswers')
        if (k === "analysis") return t('cvAnalysis')
        if (k === "salary") return t('avgSalary')
        return t(k)
    }, [cv.Metadata.name])

    const onClik = useCallback(k => {
        const i = _.findIndex(crumbList, e => e === k)
        if (crumbList[i] === "cv") return n(`/app/web/cv/edit`)
        n(`/app/web/${_.slice(crumbList, 0, i + 1).join("/")}`)
    }, [crumbList])

    // ------------------------------------------------------------------------------------------------------------------------
    if (platform !== "web") return null
    return (
        <Breadcrumbs className="px-8 py-2 border-b border-divider">
            <BreadcrumbItem key={"home"} href="/app/web/">Home</BreadcrumbItem>
            {
                crumbList.map((e, i) => (
                    <BreadcrumbItem key={e} onClick={() => (i + 1 < crumbList.length) && onClik(e)}>
                        {getCrumName(e)}
                    </BreadcrumbItem>
                ))
            }
        </Breadcrumbs>
    )
}

export default WebCVBreadcrumb