import { useSelector } from "react-redux"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Card, CardBody, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Listbox, ListboxItem, Avatar, DropdownSection, } from "@nextui-org/react"

import { t } from "cvpop-localization-sdk"
import { icons } from "cvpop-assets-sdk"

import _ from "lodash"
import moment from "moment"

import { openUrl } from "../../../../controllers/app"
import { useColorScheme } from "../../../../controllers/hooks"

import WebIcon from "../../../atoms/WebIcon"
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";


const WebJobDetail = ({ }) => {

    // ------------------------------------------------------------------------------------------------------------------------ 
    const { jobList } = useSelector(state => state.jobReducer)
    const { jobId, platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    // const [job, setJob] = useState({})

    // ------------------------------------------------------------------------------------------------------------------------ 
    const isWeb = useMemo(() => platform === "web", [platform])

    // const isCvOn = useMemo(() => getBoolean(remoteConfig, "openAIOn"), [remoteConfig])

    // const cvApi = useMemo(() => httpsCallable(functions, 'createcvfromjob', { timeout: 120000 }), [functions]);

    const job = useMemo(() => _.find(jobList, j => j.id === jobId), [jobList, jobId]) || {}
    // const salary = useMemo(() => getJobSalary(jobDraft), [jobDraft])

    // const actions = useMemo(() => (_.compact([
    //     // isCvOn && { id: "cv", title: t("createSuiteableCv"), icon: userPro ? "note_add" : "workspace_premium", iconColor: userPro ? "" : `text-yellow-400 filled` },
    //     { id: "apply", title: t("applyNow"), icon: "open_in_new" },
    // ])), [user, userPro])

    // ------------------------------------------------------------------------------------------------------
    const { phcompanylogo } = icons
    const { title, company, datePosted, location, description, employmentType, image, salaryRange, remote, jobProviders } = job


    // // ------------------------------------------------------------------------------------------------------
    // const onAction = useCallback(id => {
    //     if (id === "apply") return openUrl(job.url, platform)

    //     if (id === "cv") {

    //         if (cvList.length >= maxCvLength) return alert(t("cvNumLimit"), t("cvNumLimitMsg"))
    //         if (!userPro) return goToPaywall(offering, platform, n)

    //         let newCv = null;

    //         d(setSpin(true))
    //         cvApi({ job, hLng })
    //             .then(({ data }) => {
    //                 const ref = doc(collection(db, "00_RESUMES_00"));
    //                 newCv = createCvObject(fbuser, user, ref.id, dataToJson(data, createFbId), "createdByJob", job);
    //                 return setDoc(doc(db, "00_RESUMES_00", ref.id), newCv)
    //             }).then(() => {
    //                 d(setCv({ cv: newCv, ignoreListener: true }))
    //                 n(`/app/${platform}/cv/edit`)
    //             })
    //             .catch(e => errorHandler(alert, "err_create_cv_from_job", e))
    //             .finally(() => d(setSpin(false)))

    //     }

    // }, [user, userPro, cvList, job])


    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="w-8/12" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
            <div className="overflow-y-auto" style={{ height: `calc(100% - 104px)` }}>
                <div className="p-8">
                    <p className="text-3xl font-bold">{title}</p>

                    <WebListItemHeaderView text={t('company')} />
                    <Card shadow={"none"}>
                        <CardBody className="p-2">
                            <Listbox aria-label="Actions" variant={"flat"} disabledKeys={["company"]}>
                                <ListboxItem
                                    key={"company"}
                                    title={company}
                                    className={`opacity-100`}
                                    startContent={(
                                        <Avatar
                                            radius="sm"
                                            className="bg-transparent"
                                            imgProps={{ className: "object-contain p-1" }} src={image || phcompanylogo} name={company}
                                        />
                                    )}
                                />
                            </Listbox>
                        </CardBody>
                    </Card>

                    <WebListItemHeaderView text={t('moreInfo')} />
                    <Card shadow={"none"}>
                        <CardBody className="p-2">
                            <Listbox aria-label="Actions" variant={"flat"} disabledKeys={["location", "datePosted", "remoteJob", "employmentType", "salaryRange"]}>
                                {
                                    location && (
                                        <ListboxItem
                                            key={"location"}
                                            title={location}
                                            className={`opacity-100`}
                                            startContent={<WebIcon name="location_on" />}
                                        />
                                    )
                                }
                                {
                                    datePosted && (
                                        <ListboxItem
                                            key={"datePosted"}
                                            title={moment(datePosted).isValid() ? moment(datePosted).fromNow() : datePosted}
                                            className={`opacity-100`}
                                            startContent={<WebIcon name="calendar_clock" />}
                                        />
                                    )
                                }
                                {
                                    remote && (
                                        <ListboxItem
                                            key={"remoteJob"}
                                            title={t("remoteJob")}
                                            className={`opacity-100`}
                                            startContent={<WebIcon name="in_home_mode" />}
                                        />
                                    )
                                }
                                {
                                    employmentType && (
                                        <ListboxItem
                                            key={"employmentType"}
                                            title={employmentType}
                                            className={`opacity-100`}
                                            startContent={<WebIcon name="work_history" />}
                                        />
                                    )
                                }
                                {
                                    salaryRange && (
                                        <ListboxItem
                                            key={"salaryRange"}
                                            title={salaryRange}
                                            className={`opacity-100`}
                                            startContent={<WebIcon name="payments" />}
                                        />
                                    )
                                }
                            </Listbox>
                        </CardBody>
                    </Card>

                    <WebListItemHeaderView text={t('description')} />
                    <Card shadow={"none"} className="mt-4">
                        <CardBody>
                            {description}
                        </CardBody>
                    </Card>

                </div>
            </div>
            <div className="p-8">
                <Dropdown backdrop={"blur"} className={`${cs} text-foreground`}>
                    <DropdownTrigger>
                        <Button color={"primary"} fullWidth>
                            {t("showOptions")}
                        </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                        color={"primary"}
                        aria-label="Static Actions"
                    >
                        <DropdownSection title={t("applyNow")} >
                            {jobProviders.map(e => (
                                <DropdownItem
                                    key={e.jobProvider}
                                    title={e.jobProvider}
                                    endContent={<WebIcon name={"open_in_new"} />}
                                    onClick={() => openUrl(e.url, platform)}
                                />
                            ))}
                        </DropdownSection>

                    </DropdownMenu>
                </Dropdown>

            </div>
        </div>
    )
}

export default WebJobDetail